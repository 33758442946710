import { DateUtils, TDateTime } from '../../../../../delphi_compatibility/DateUtils'
import { getGlobalDataDownloader } from '../../../../../globals/GlobalDataDownloader'
import GlobalSymbolList from '../../../../../globals/GlobalSymbolList'
import CommonConstants from '../../../../common/CommonConstants'
import { TimeframeUtils } from '../../../../common/TimeframeUtils'
import { UNIX_DATE_MIN, UNIX_DATE_SEC_OVER_MAX } from '../../../DataConstants'
import { TChunkMapStatus } from '../../../TChunkMapStatus'
import { TDataFormat } from '../../../DataEnums'
import TLastBarBuilder from '../../../BarBuilding/LastBarBuilding/LastBarBuilder'
import { TBarChunk } from '../../../chunks/BarChunk'
import { TChunkStatus, TNoExactMatchBehavior } from '../../../chunks/ChunkEnums'
import { TListOfChunks } from '../../../chunks/ListOfChunks'
import Map_converter from '../../../data_converters/Map_converter'
import { TDataAvailability, TDataArrayEvents } from '../../../data_downloading/DownloadRelatedEnums'
import { TBarsMapDownloadTask } from '../../../data_downloading/DownloadTasks/BarMapDownloadTask'
import DataNotDownloadedYetError from '../../../data_errors/DataUnavailableError'
import { TDataDescriptor, TDataTypes } from '../../DataDescriptionTypes'
import { TSearchMode } from '../../FXDataArrays'
import IFMBarsArray from '../IFMBarsArray'
import { TMappedChunkedArray } from '../MappedChunkedArray'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { ChunkBuilder } from '@fto/lib/ft_types/data/BarBuilding/chunk_building/ChunkBuilder'
import { TSymbolData } from '../../../SymbolData'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import InvalidDataError from '../../../data_errors/InvalidDataError'
import MathUtils from '@fto/lib/utils/MathUtils'
import { TBarRecord } from '../../../DataClasses/TBarRecord'
import { TTickRecord } from '../../../DataClasses/TTickRecord'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'
import { EMPTY_MIN_MAX_BID_VALUES, IMinMaxBidValues } from '../../../DataUtils/IMinMaxValues'

class TPositionInTesting {
    // public fLastKnownDateInTesting: TDateTime | null = null; //the date of last tick which we have processed in this array
    public LastItemInTestingIndex!: number
    public LastBarInTestingFirstDate!: TDateTime //was PeriodStart in Delphi
    public LastBarInTestingLastDate!: TDateTime //was PeriodEnd in Delphi
    public CurrentUnfinishedBar!: TBarRecord

    constructor() {
        this.Reset()
    }

    public Reset() {
        // this.fLastKnownDateInTesting = null;
        this.LastItemInTestingIndex = CommonConstants.EMPTY_INDEX
        this.LastBarInTestingFirstDate = DateUtils.EmptyDate
        this.LastBarInTestingLastDate = DateUtils.EmptyDate
        this.CurrentUnfinishedBar = new TBarRecord(0, 0, 0, 0, 0, 0)
    }
}

// TFMBarsArray class
export class TFMBarsArray extends TMappedChunkedArray<TBarRecord, TBarChunk> implements IFMBarsArray {
    private _positionInTesting: TPositionInTesting = new TPositionInTesting()

    private _isSeeked = false
    private _currentChunk: TBarChunk | null = null
    private _nextChunk: TBarChunk | null = null
    private _previousChunk: TBarChunk | null = null

    //for test purposes
    private _ignoreBarCountComparison = false

    constructor(timeframe: number, symbolName: string, broker: string) {
        const aDataDescriptor: TDataDescriptor = {
            broker: broker,
            symbolName: symbolName,
            dataType: TDataTypes.dt_Bars,
            timeframe: timeframe
        }

        super(aDataDescriptor)

        //TODO: maybe we can remove this when the chunks map from server is already linked
        this.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundLinkChunksAndLoadFutureChunk)
    }

    public ResetSeekStatus(): void {
        this._isSeeked = false
    }

    //for test purposes
    public set IgnoreBarCountComparison(value: boolean) {
        this._ignoreBarCountComparison = value
    }

    private get SymbolData(): TSymbolData {
        const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol(this.DataDescriptor.symbolName)
        if (!symbol) {
            throw new StrangeError('We should be able to find symbol here')
        }
        return symbol
    }

    public get IsSeeked(): boolean {
        return this._isSeeked
    }

    public get totalBarsCount(): number {
        if (this.ChunkMapStatus === TChunkMapStatus.cms_Loaded) {
            return this.fChunks.LastItem.FirstGlobalIndex + this.fChunks.LastItem.Count
        } else {
            throw new DataNotDownloadedYetError('BarsCount - Bars count is not available - no chunk map yet')
        }
    }

    public GetDataAvailability(startDate: number, endDate: number): TDataAvailability {
        switch (this.ChunkMapStatus) {
            case TChunkMapStatus.cms_Empty:
            case TChunkMapStatus.cms_Loading: {
                return TDataAvailability.da_NoMapYet
            }
            case TChunkMapStatus.cms_Loaded: {
                if (!this.IsSeeked || !this.LastItemInTestingIndexAvailable) {
                    return TDataAvailability.da_NotSeekedYet
                }

                const relevantChunks = this.GetChunksForRangeDates(startDate, endDate)
                if (relevantChunks.length === 0) {
                    //Is the range outside of available data?
                    const firstDateInHistory = this.GetFirstAvailableDate()
                    const lastDateInHistory =
                        this.LastBarInTestingEndDate + DateUtils.OneMinute * this.DataDescriptor.timeframe
                    if (endDate < firstDateInHistory || startDate > lastDateInHistory) {
                        return TDataAvailability.da_MissingForThisDateRange
                    }
                    throw new StrangeError(
                        `relevantChunks.length === 0, unexpected behavior for ${this.DName}, startDate: ${startDate} endDate: ${endDate}`
                    )
                } else {
                    for (const chunk of relevantChunks) {
                        if (chunk.Status !== TChunkStatus.cs_Loaded) {
                            return TDataAvailability.da_SomeAvailable
                        }
                    }
                    return TDataAvailability.da_AllAvailable
                }
            }
            default: {
                throw new StrangeError('Unsupported chunk map status')
            }
        }
    }

    public GetItemByDate(DateTime: TDateTime, noExactMatchBehavior: TNoExactMatchBehavior): TBarRecord | null {
        const chunk = this.GetChunkByDate(DateTime)
        if (chunk && chunk.Status === TChunkStatus.cs_Loaded) {
            return chunk.GetItemByDateTime(DateTime, noExactMatchBehavior)
        } else {
            throw new DataNotDownloadedYetError('Chunk data is not available - GetItemByDate')
        }
    }

    public GetItemByGlobalIndex(
        aGlobalIndex: number,
        downloadChunkIfEmpty = true,
        allowOutOfBound = false
    ): TBarRecord | null {
        //return last unfinished bar if we are at the end of the testing
        if (this.LastItemInTestingIndex === aGlobalIndex) {
            return this.LastItemInTesting
        }
        return super.GetItemByGlobalIndex(aGlobalIndex, downloadChunkIfEmpty, allowOutOfBound)
    }

    public get LastPossibleIndexInHistory(): number {
        if (this.ChunkMapStatus === TChunkMapStatus.cms_Loaded) {
            return this.fChunks.LastItem.FirstGlobalIndex + this.fChunks.LastItem.Count - 1
        }
        //We have no idea yet
        throw new DataNotDownloadedYetError('LastPossibleIndexInHistory - no chunk map yet')
    }

    private _lastProcessedTickTime: TDateTime | null = null

    public get LastProcessedTickTime(): TDateTime | null {
        return this._lastProcessedTickTime
    }

    //set the position of the current bar to the end of the testing period
    public Seek(lastProcessedTickTimeForThisSymbol: TDateTime): TDateTime | null {
        this.ValidateSeekTime(lastProcessedTickTimeForThisSymbol)

        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            'Seeking bars array',
            this.DName,
            'to date',
            lastProcessedTickTimeForThisSymbol
        )

        if (
            this.IsSeeked &&
            this._lastProcessedTickTime &&
            DateUtils.AreEqual(this._lastProcessedTickTime, lastProcessedTickTimeForThisSymbol)
        ) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Seek,
                'Seek for barsArray',
                this.DName,
                'is already completed to date',
                lastProcessedTickTimeForThisSymbol
            )
            return lastProcessedTickTimeForThisSymbol
        }

        this._isSeeked = false
        this._lastProcessedTickTime = null

        this.MakeSureMapIsReady_throwErrorIfNot()

        const chunk = this.GetChunkByDate(lastProcessedTickTimeForThisSymbol)

        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            'SeekBarsToLastTickInTesting - Seeking to chunk',
            chunk?.DName || 'NA',
            ' status: ',
            chunk?.Status || 'NA'
        )

        if (chunk) {
            if (chunk.Status === TChunkStatus.cs_Loaded) {
                return this.finalizeSeek(chunk, lastProcessedTickTimeForThisSymbol)
            } else {
                this.triggerLoadingData(chunk)
                throw new DataNotDownloadedYetError(
                    `SeekBarsToLastTickInTesting - chunk is not available ${chunk.FirstDate} ${chunk.DName}`
                )
            }
        } else {
            throw new StrangeError(
                'Chunk is null - SeekBarsToLastTickInTesting, this should not happen since the map is downloaded and the time is in range'
            )
        }
    }

    private triggerLoadingData(chunk: TBarChunk) {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            'Bar chunk is not loaded yet',
            chunk.FirstDate,
            chunk.DName,
            'status:',
            chunk.Status
        )
        if (!chunk.IsLoadedOrLoading) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Seek,
                'Starting loading data for chunk needed for seek',
                chunk.FirstDate,
                chunk.DName
            )
            ChunkBuilder.Instance.BuildChunk(chunk)
        }
        //the chunk with seeked data is not available yet
    }

    private finalizeSeek(chunk: TBarChunk, lastProcessedTickTimeForThisSymbol: number) {
        this.SeekCurrentBarInTestingPointerTo(chunk, lastProcessedTickTimeForThisSymbol)

        this._isSeeked = true
        this._lastProcessedTickTime = lastProcessedTickTimeForThisSymbol
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            `Seek for barsArray`,
            this.DName,
            `completed successfully to date:`,
            lastProcessedTickTimeForThisSymbol
        )
        this.Events.EmitEvent(TDataArrayEvents.de_SeekCompleted)
        return lastProcessedTickTimeForThisSymbol
    }

    private ValidateSeekTime(dateToSeekTo: number) {
        if (
            dateToSeekTo < this.SymbolData.VeryFirstDateInHistory ||
            dateToSeekTo > this.SymbolData.VeryLastDateInHistory
        ) {
            //we can only come here after seeking ticks, so we would come here with a date of actual tick which should lie within the symbol data range
            throw new StrangeError('Trying to seek BARS array to date outside of the symbol data range')
        }
    }

    private MakeSureMapIsReady_throwErrorIfNot() {
        if (this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            this.InitMapIfNecessary()
            throw new DataNotDownloadedYetError(
                `SeekBarsToLastTickInTesting - chunk map is not available yet${this.DName}`
            )
        }
    }

    private SeekCurrentBarInTestingPointerTo(
        loadedChunkAtThisDate: TBarChunk,
        lastProcessedTickTimeForThisSymbol: TDateTime
    ): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            'Seeking current bar in testing pointer to',
            lastProcessedTickTimeForThisSymbol,
            loadedChunkAtThisDate.DName
        )
        let currentBar: TBarRecord

        this.checkChunk_throwErrorIfInvalid(loadedChunkAtThisDate, lastProcessedTickTimeForThisSymbol)

        const finishedLastBarIndexForThisDate = loadedChunkAtThisDate.GetGlobalIndexByDate(
            lastProcessedTickTimeForThisSymbol,
            false,
            TNoExactMatchBehavior.nemb_ReturnNearestLower
        )

        if (finishedLastBarIndexForThisDate === CommonConstants.EMPTY_INDEX) {
            throw new StrangeError('finishedLastBarIndexForThisDate is not available')
        }

        const finishedLastBar = loadedChunkAtThisDate.GetItemByGlobalIndex(finishedLastBarIndexForThisDate)

        if (!finishedLastBar) {
            throw new StrangeError('finishedLastBar is null')
        }

        if (
            TBarRecord.DateInsideBar(lastProcessedTickTimeForThisSymbol, finishedLastBar, this.DataDescriptor.timeframe)
        ) {
            //we are inside existing bar, so let's build it to date
            currentBar = TLastBarBuilder.BuildLastBarToDate(
                this.DataDescriptor,
                lastProcessedTickTimeForThisSymbol
                // finishedLastBarIndexForThisDate
            )
        } else {
            //most likely we are at the gap between bars, so let's take the last known bar (in full) as a current bar
            currentBar = TBarRecord.MakeACopy(finishedLastBar)
        }

        this.SetCurrentBar(finishedLastBarIndexForThisDate, currentBar)
    }

    private checkChunk_throwErrorIfInvalid(loadedChunkAtThisDate: TBarChunk, currentDateInTesting: number) {
        if (loadedChunkAtThisDate.Status !== TChunkStatus.cs_Loaded) {
            throw new StrangeError('Chunk is not loaded')
        }

        if (loadedChunkAtThisDate.Count === 0) {
            throw new StrangeError('Chunk is empty - SeekCurrentBarInTestingPointerTo')
        }

        if (!loadedChunkAtThisDate.DateInside(currentDateInTesting)) {
            throw new StrangeError('Date is not inside the chunk')
        }
    }

    private SetCurrentBar(currentBarGlobalIndex: number, currentBar: TBarRecord) {
        this._positionInTesting.LastBarInTestingFirstDate = currentBar.DateTime
        //TODO: should we "stretch" the bar till the next bar? (in case there is a gap between bars)
        this._positionInTesting.LastBarInTestingLastDate = TimeframeUtils.GetPeriodEnd(
            currentBar.DateTime + CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME,
            this.timeframe
        )
        this._positionInTesting.LastItemInTestingIndex = currentBarGlobalIndex
        this._positionInTesting.CurrentUnfinishedBar = currentBar
        //FIXME: return this check for all subscription types when the tick and minutes are synced this.
        if (CommonUtils.IsBasicDataSubscription) {
            this.__checkCurrentBarConsistency()
        }
    }

    //FIXME: return this check when the tick and minutes are synced
    private __checkCurrentBarConsistency() {
        this.__compareCurrentUnfinishedBarWithFinished()

        if (this._positionInTesting.LastItemInTestingIndex > 0) {
            this.__checkPreviousBarConsistency()
        }

        if (this._positionInTesting.LastItemInTestingIndex < this.LastPossibleIndexInHistory) {
            this.__checkNextBarConsistency()
        }
    }

    private __checkNextBarConsistency() {
        const nextBar = this.GetItemByGlobalIndex(this._positionInTesting.LastItemInTestingIndex + 1)

        if (!nextBar) {
            //this is ok because the next chunk may not be loaded yet
            return
        }

        if (nextBar.DateTime < this._positionInTesting.CurrentUnfinishedBar.DateTime) {
            throw new StrangeError(
                `__checkCurrentBarConsistency Next bar is before current bar ${nextBar.DateTime} ${this._positionInTesting.CurrentUnfinishedBar.DateTime}`
            )
        }
    }

    private __compareCurrentUnfinishedBarWithFinished() {
        //gettting the finished bar directly from the chunk to avoid replacing it with the current bar
        const lastBarChunk = this.GetChunkByGlobalIndex(this._positionInTesting.LastItemInTestingIndex)
        if (!lastBarChunk) {
            throw new StrangeError('lastBarChunk is not available')
        }
        if (lastBarChunk.Status !== TChunkStatus.cs_Loaded) {
            throw new StrangeError('__compareCurrentUnfinishedBarWithFinished - lastBarChunk is not loaded')
        }
        const currentFinishedBar = lastBarChunk.GetItemByGlobalIndex(this._positionInTesting.LastItemInTestingIndex)

        if (!currentFinishedBar) {
            throw new StrangeError('__compareCurrentUnfinishedBarWithFinished current bar is not available')
        }

        //FIXME: return this check for all subscription types when the tick and bars data from server are in sync
        if (
            CommonUtils.IsBasicDataSubscription &&
            !DateUtils.AreEqual(currentFinishedBar.DateTime, this._positionInTesting.CurrentUnfinishedBar.DateTime)
        ) {
            throw new StrangeError(
                `__checkCurrentBarConsistency currentBar Dates are not equal ${currentFinishedBar.DateTime} ${this._positionInTesting.CurrentUnfinishedBar.DateTime}`
            )
        }
    }

    private __checkPreviousBarConsistency() {
        const previousBar = this.GetItemByGlobalIndex(this._positionInTesting.LastItemInTestingIndex - 1)

        if (!previousBar) {
            //probably there may be some cases when the previous bar is not available yet
            return
        }

        if (previousBar.DateTime > this._positionInTesting.CurrentUnfinishedBar.DateTime) {
            throw new StrangeError(
                `__checkCurrentBarConsistency Previous bar is after current bar ${previousBar.DateTime} ${this._positionInTesting.CurrentUnfinishedBar.DateTime}`
            )
        }
    }

    private SetPositionInTestingToNewBar(newBarStartDate: TDateTime, theBarGlobalIndex: number, price = 0) {
        this.throwIfBarChunkIsNotLoaded(theBarGlobalIndex)
        DebugUtils.logTopic(
            ELoggingTopics.lt_BarBuilding,
            'SetPositionInTestingToNewBar',
            this.DName,
            newBarStartDate,
            DateUtils.DF(newBarStartDate),
            theBarGlobalIndex,
            price
        )
        this._positionInTesting.LastBarInTestingFirstDate = newBarStartDate
        //TODO: should we "stretch" the bar till the next bar? (in case there is a gap between bars)
        this._positionInTesting.LastBarInTestingLastDate = TimeframeUtils.GetPeriodEnd(newBarStartDate, this.timeframe)

        this._positionInTesting.LastItemInTestingIndex = theBarGlobalIndex
        if (price > 0) {
            this._positionInTesting.CurrentUnfinishedBar = new TBarRecord(
                newBarStartDate,
                price,
                price,
                price,
                price,
                0
            )
        } else {
            const currentBid = this.SymbolData.bid ?? 0

            if (currentBid > 0) {
                this._positionInTesting.CurrentUnfinishedBar = new TBarRecord(
                    newBarStartDate,
                    currentBid,
                    currentBid,
                    currentBid,
                    currentBid,
                    0
                )
            } else {
                throw new StrangeError('currentBid is not available')
            }
            this._positionInTesting.CurrentUnfinishedBar = this.InitCurrentUnfinishedBarBasedOnPrevClose()
        }
        //FIXME: return this for all subscription types when tick and bars are synced (waiting for backend for now)
        if (CommonUtils.IsBasicDataSubscription) {
            this.__checkCurrentBarConsistency()
        }
    }

    private throwIfBarChunkIsNotLoaded(currentBarGlobalIndex: number) {
        const chunk = this.GetChunkByGlobalIndex(currentBarGlobalIndex)
        if (!chunk) {
            throw new StrangeError('throwIfBarChunkIsNotLoaded - Chunk is not available')
        }
        if (chunk.Status !== TChunkStatus.cs_Loaded) {
            ChunkBuilder.Instance.BuildChunk(chunk)
            throw new DataNotDownloadedYetError('Chunk of the current bar is not loaded yet, lets wait')
        }
    }

    protected InitCurrentUnfinishedBarBasedOnPrevClose(): TBarRecord {
        //FIXME: return a real bar here
        if (this._positionInTesting.LastItemInTestingIndex > 1) {
            const previousBar = this.GetItemByGlobalIndex(this._positionInTesting.LastItemInTestingIndex - 1)
            if (previousBar) {
                return new TBarRecord(
                    this._positionInTesting.LastBarInTestingFirstDate,
                    previousBar.close,
                    previousBar.close,
                    previousBar.close,
                    previousBar.close,
                    0
                )
            }
        }
        return new TBarRecord(this._positionInTesting.LastBarInTestingFirstDate, 0, 0, 0, 0, 0)
    }

    public get LastItemInTesting(): TBarRecord {
        return this._positionInTesting.CurrentUnfinishedBar
    }

    public get LastItemInTestingIndex(): number {
        return this._positionInTesting.LastItemInTestingIndex
    }

    //checks if we are in the range from 0 to LastItemInTestingIndex
    public IsIndexValid(index: number): boolean {
        return index >= 0 && index <= this.LastItemInTestingIndex
    }

    public get timeframe(): number {
        return this.DataDescriptor.timeframe
    }

    public get LastBarInTestingEndDate(): TDateTime {
        return this._positionInTesting.LastBarInTestingLastDate
    }

    public get LastBarInTestingIndex(): number {
        return this.LastItemInTestingIndex
    }

    public GetValue(globalIndex: number, sm: TSearchMode): number {
        const bar = this.GetItemByGlobalIndex(globalIndex)
        if (bar) {
            switch (sm) {
                case TSearchMode.sm_Open: {
                    return bar.open
                }
                case TSearchMode.sm_High: {
                    return bar.high
                }
                case TSearchMode.sm_Low: {
                    return bar.low
                }
                case TSearchMode.sm_Close: {
                    return bar.close
                }
                case TSearchMode.sm_Volume: {
                    return bar.volume
                }
                default: {
                    throw new StrangeError('Invalid search mode')
                }
            }
        }
        return CommonConstants.EMPTY_DATA_VALUE
    }

    public GetMax(firstPos: number, lastPos: number, sm: TSearchMode): number {
        if (!this.LastItemInTestingIndexAvailable) {
            return 0
        }

        firstPos = Math.max(0, firstPos)
        lastPos = Math.min(this.LastItemInTestingIndex, lastPos)

        let result = this.GetValue(firstPos, sm) ?? CommonConstants.EMPTY_DATA_VALUE
        for (let i = firstPos + 1; i <= lastPos; i++) {
            const value = this.GetValue(i, sm)
            if (value !== CommonConstants.EMPTY_DATA_VALUE && value > result) {
                result = value
            }
        }

        return result
    }

    public GetMin(firstPos: number, lastPos: number, sm: TSearchMode): number {
        if (!this.LastItemInTestingIndexAvailable) {
            return 0
        }

        firstPos = Math.max(0, firstPos)
        lastPos = Math.min(this.LastItemInTestingIndex, lastPos)

        let result = CommonConstants.EMPTY_DATA_VALUE
        for (let i = firstPos; i <= lastPos; i++) {
            const value = this.GetValue(i, sm)
            if (
                value !== CommonConstants.EMPTY_DATA_VALUE &&
                (result === CommonConstants.EMPTY_DATA_VALUE || value < result)
            ) {
                result = value
            }
        }

        return result
    }

    public GetSum(firstPos: number, lastPos: number, sm: TSearchMode): number {
        let result = 0
        for (let i = firstPos; i <= lastPos; i++) {
            const value = this.GetValue(i, sm)
            if (value !== CommonConstants.EMPTY_DATA_VALUE) {
                result += value
            }
        }

        return result
    }

    //checks if the date is within the last bar in testing
    protected withinLastBarInTestingPeriod(dateTime: TDateTime): boolean {
        if (this.LastItemInTestingIndexAvailable) {
            return (
                dateTime >= this._positionInTesting.LastBarInTestingFirstDate &&
                dateTime <= this._positionInTesting.LastBarInTestingLastDate
            )
        } else {
            throw new DataNotDownloadedYetError(
                `IsInLastBarInTesting_InPeriod - Last bar in testing is not available for ${this.DName}`
            )
        }
    }

    public AddSingleTick(tick: TTickRecord): void {
        if (!this.IsSeeked) {
            throw new StrangeError(`AddSingleTick - trying to add tick to not seeked array ${this.DName}`)
        }

        this.updateLastBarInTesting(tick)

        this._lastProcessedTickTime = tick.DateTime
    }

    private updateLastBarInTesting(tick: TTickRecord) {
        if (this.withinLastBarInTestingPeriod(tick.DateTime)) {
            const bid: number = tick.bid
            // if date in current period - update values
            this._positionInTesting.CurrentUnfinishedBar.close = bid

            this._positionInTesting.CurrentUnfinishedBar.high = Math.max(
                this._positionInTesting.CurrentUnfinishedBar.high,
                bid
            )
            this._positionInTesting.CurrentUnfinishedBar.low = Math.min(
                this._positionInTesting.CurrentUnfinishedBar.low,
                bid
            )

            this._positionInTesting.CurrentUnfinishedBar.volume += tick.volume
        } else {
            //the tick is not within the last bar
            //let's check if it is before the last bar which would be an error
            if (tick.DateTime < this.LastBarInTestingEndDate) {
                throw new StrangeError('updateLastBarInTesting - tick.DateTime < this.LastBarInTestingEndDate')
            }
            //In Delphi this part saved the bar to the array and started a new bar
            this.StartLastBarByTick(tick)
        }
    }

    protected StartLastBarByTick(tick: TTickRecord): void {
        // this.SetPeriodOfLastBar(tick.DateTime);
        const newBarStartDateTime = TimeframeUtils.GetPeriodStart(tick.DateTime, this.timeframe)
        if (DateUtils.IsEmpty(newBarStartDateTime)) {
            throw new StrangeError('newBarStartDateTime is not available')
        }
        if (newBarStartDateTime > tick.DateTime) {
            throw new StrangeError(
                'newBarStartDateTime > tick.DateTime date of a new bar start cannot be higher than the date of the tick'
            )
        }

        //FIXME: we potentially can use this< BUT we need to have ticks that are exactly like bars and also we cannot skip any ticks
        // const newBarIndex = this.LastItemInTestingIndex + 1

        this.SetPositionInTestingToNewBar(newBarStartDateTime, this.LastItemInTestingIndex + 1, tick.bid)
    }

    public ImportServerDataIntoChunkMap(
        chunksMapServerData: any,
        newChunksMapStatus: TChunkMapStatus,
        dataFormat: TDataFormat
    ): void {
        let newEmptyChunks

        switch (dataFormat) {
            case TDataFormat.df_MSGPPACK: {
                newEmptyChunks = Map_converter.ConvertMSGPackServerChunksToEmptyChunks(
                    chunksMapServerData,
                    this.DataDescriptor
                )
                break
            }
            case TDataFormat.df_Json: {
                newEmptyChunks = Map_converter.ConvertJSONServerChunksToEmptyChunks(
                    chunksMapServerData,
                    this.DataDescriptor
                )
                break
            }
            case TDataFormat.df_MockObjects: {
                newEmptyChunks = Map_converter.ConvertMockServerChunksToEmptyChunks(
                    chunksMapServerData,
                    this.DataDescriptor
                )
                break
            }
            default: {
                throw new InvalidDataError('Data format not supported')
            }
        }

        this.ApplyChunksMap(newEmptyChunks, newChunksMapStatus)
    }

    protected ApplyChunksMap(newEmptyChunks: TListOfChunks<TBarChunk>, newChunksMapStatus: TChunkMapStatus): void {
        if (this.fChunkMapStatus === TChunkMapStatus.cms_Loaded) {
            //this should be changed when we start updating data daily
            throw new StrangeError(`Chunk map is already loaded, why are we trying to re-load it? ${this.DName}`)
        }

        DebugUtils.logTopic(ELoggingTopics.lt_Maps, 'Applying chunks map', this.DataDescriptor, 'name:', this.DName)

        this.fLastAccChunkIndex = CommonConstants.EMPTY_INDEX

        //this will be useful when we will update data on the fly
        this.MergeInOldChunks(newEmptyChunks, this.fChunks)

        this.fChunks = newEmptyChunks

        this.SetDebugValues()

        this.SubscribeNewChunksToEvents()
        this.LinkChunks()

        //doing it just in case, but hopefully the backend already provides correct start and end dates
        this.CorrectFirstAndLastDates()

        //make sure that status is updated before doing Seek. The Seek needs to know that the map was imported
        this.fChunkMapStatus = newChunksMapStatus

        this.Events.EmitEvent(TDataArrayEvents.de_MapDownloaded, this)

        this.__debugCheckChunksMapConsistency()
    }

    private __debugCheckChunksMapConsistency() {
        if (DebugUtils.DebugMode) {
            let cumulativeGlobalIndex = 0
            for (let i = 0; i < this.fChunks.Count - 1; i++) {
                const chunk = this.fChunks[i]
                const nextChunk = this.fChunks[i + 1]
                //check if the chunks are sorted by date
                if (chunk.FirstDate > nextChunk.FirstDate) {
                    throw new StrangeError(
                        'Invalid dates in chunks, the chunks are not sorted',
                        chunk.DName,
                        chunk.FirstDate,
                        'next chunk:',
                        nextChunk.DName,
                        nextChunk.FirstDate
                    )
                }
                //check if the chunks are sorted by global index
                if (chunk.FirstGlobalIndex + chunk.Count !== nextChunk.FirstGlobalIndex) {
                    throw new StrangeError(
                        'Invalid global indexes in chunks',
                        chunk.DName,
                        chunk.FirstGlobalIndex,
                        chunk.Count,
                        nextChunk.DName,
                        nextChunk.FirstGlobalIndex
                    )
                }
                //check if the cumulative global index is correct
                if (chunk.FirstGlobalIndex !== cumulativeGlobalIndex) {
                    throw new StrangeError(
                        'Invalid cumulative global indexes in chunks',
                        chunk.DName,
                        chunk.FirstGlobalIndex,
                        cumulativeGlobalIndex
                    )
                }
                cumulativeGlobalIndex += chunk.Count
            }
            //check if the last chunk is correct
            const lastChunk = this.fChunks.LastItem
            if (lastChunk.FirstGlobalIndex !== cumulativeGlobalIndex) {
                throw new StrangeError(
                    'Invalid cumulative global indexes in last chunk',
                    lastChunk.DName,
                    lastChunk.FirstGlobalIndex,
                    cumulativeGlobalIndex
                )
            }
            cumulativeGlobalIndex += lastChunk.Count
            if (cumulativeGlobalIndex - 1 !== this.LastPossibleIndexInHistory) {
                throw new StrangeError(
                    'Invalid LastPossibleIndexInHistory',
                    cumulativeGlobalIndex,
                    this.LastPossibleIndexInHistory
                )
            }
        }
    }

    public CorrectFirstAndLastDates(): void {
        if (this.fChunks.Count === 0) {
            //ignore empty bar arrays
            return
        }
        this.CorrectStartDateForFirstChunk()
        this.CorrectEndDateForLastChunk()
    }

    private CorrectStartDateForFirstChunk(): void {
        if (this.fChunks.Count === 0) {
            //ignore empty bar arrays
            return
        }
        const firstChunk = this.fChunks.at(0)
        if (!firstChunk) {
            throw new StrangeError('First chunk is not available')
        }
        const firstPossibleDateForSymbol = this.SymbolData.VeryFirstDateInHistory
        firstChunk.CorrectFirstDate(Math.max(firstChunk.FirstDate, firstPossibleDateForSymbol))
    }

    private CorrectEndDateForLastChunk(): void {
        if (this.fChunks.Count === 0) {
            //ignore empty bar arrays
            return
        }
        const lastChunk = this.fChunks.at(-1)
        if (!lastChunk) {
            throw new StrangeError('Last chunk is not available')
        }
        const lastPossibleDateForSymbol = this.SymbolData.VeryLastDateInHistory
        lastChunk.LastPossibleDate = Math.min(lastChunk.LastPossibleDate, lastPossibleDateForSymbol)
    }

    private SetDebugValues(): void {
        for (let i = 0; i < this.fChunks.Count; i++) {
            const chunk = this.fChunks[i]
            chunk.SetName(
                `BAR chunk of ${this.DName}_${i.toString()}_${chunk.FirstDate}-${MathUtils.roundTo(
                    chunk.LastPossibleDate,
                    4
                )}_${DateUtils.DF(chunk.FirstDate)}`
            )
            chunk.IgnoreBarCountComparison = this._ignoreBarCountComparison
        }
    }

    public isDateInAvailableRange(date: number): boolean {
        return (
            DateUtils.MoreOrEqual(date, this.SymbolData.VeryFirstDateInHistory) &&
            DateUtils.LessOrEqual(date, this.SymbolData.VeryLastDateInHistory)
        )
    }

    public UpdateChunksInfo(projectLastTickTime: TDateTime): void {
        if (!this.isDateInAvailableRange(projectLastTickTime)) {
            //no need to do anything while we are out of range
            return
        }
        if (this.ChunkMapStatus === TChunkMapStatus.cms_Loaded && this.LastItemInTestingIndexAvailable) {
            this.SetCurrentChunk(projectLastTickTime)
            this.SetNeighboringChunks()

            this.LoadDataForCurrentAndNeighbors()
        }
    }

    public onTimezoneOrDSTChanged() {
        this.fChunks.forEach((chunk) => {
            chunk.onTimezoneorDstChanged()
        })
        this.LinkChunks()
    }

    private LoadDataForCurrentAndNeighbors() {
        this.LoadDataForChunkIfNecessary(this._currentChunk)
        this.LoadDataForChunkIfNecessary(this._nextChunk)
        this.LoadDataForChunkIfNecessary(this._previousChunk)
    }

    private LoadDataForChunkIfNecessary(chunk: TBarChunk | null) {
        if (chunk && !chunk.IsLoadedOrLoading) {
            ChunkBuilder.Instance.BuildChunk(chunk)
        }
    }

    private SetNeighboringChunks() {
        if (this._currentChunk) {
            this._nextChunk = this.GetChunkByGlobalIndex(this._currentChunk.LastGlobalIndex + 1)
            this._previousChunk = this.GetChunkByGlobalIndex(this._currentChunk.FirstGlobalIndex - 1)
        }
    }

    private SetCurrentChunk(projectLastTickTime: TDateTime) {
        if (!this._currentChunk || !this._currentChunk.DateInside(projectLastTickTime)) {
            this._currentChunk = this.GetChunkByDate(projectLastTickTime)
        }
    }

    public IsDataReady(): boolean {
        return (
            this.ChunkMapStatus === TChunkMapStatus.cms_Loaded &&
            this._currentChunk !== null &&
            this._currentChunk.Status === TChunkStatus.cs_Loaded &&
            this._isSeeked
        )
    }

    private SubscribeNewChunksToEvents() {
        for (let i = 0; i < this.fChunks.Count; i++) {
            this.SubscribeNewChunkToEvents(this.fChunks[i])
        }
    }

    private boundPassForwardChunkLoadedEvent = this.PassForwardChunkLoadedEvent.bind(this)
    private PassForwardChunkLoadedEvent(chunk: TBarChunk) {
        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, chunk)
    }

    private SubscribeNewChunkToEvents(newChunk: TBarChunk) {
        newChunk.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundPassForwardChunkLoadedEvent)
    }

    public LinkChunks(): void {
        if (this.fChunks.Count > 0 && this.fChunks[0].Status === TChunkStatus.cs_Loaded && this.fChunks[0].Count > 0) {
            this.fChunks[0].CorrectFirstDateByFirstBar()
        }
        for (let i = 0; i < this.fChunks.Count - 1; i++) {
            const chunk = this.fChunks[i]
            const nextChunk = this.fChunks[i + 1]
            chunk.LastPossibleDate = nextChunk.FirstDate
            nextChunk.updateTransitionsByPrevChunk(chunk)
        }
    }

    private boundLinkChunksAndLoadFutureChunk = this.LinkChunksAndLoadFutureChunk.bind(this)
    private LinkChunksAndLoadFutureChunk(): void {
        this.LinkChunks()
        this.LoadDataForFutureChunk()
    }

    private LoadDataForFutureChunk() {
        if (this._nextChunk && this._nextChunk.Status === TChunkStatus.cs_Loaded) {
            // TODO: what will happen if this is the last chunk? We will likely get null from GetChunkByGlobalIndex
            const futureChunk = this.GetChunkByGlobalIndex(this._nextChunk.LastGlobalIndex + 1)
            if (
                futureChunk &&
                futureChunk.Status !== TChunkStatus.cs_Loaded &&
                futureChunk.Status !== TChunkStatus.cs_InQueue
            ) {
                ChunkBuilder.Instance.BuildChunk(futureChunk)
            }
        }
    }

    private MergeInOldChunks(newEmptyChunks: TListOfChunks<TBarChunk>, existingChunks: TListOfChunks<TBarChunk>): void {
        for (let i = 0; i < existingChunks.Count; i++) {
            const existingChunk = existingChunks[i]
            const newChunkIndex = newEmptyChunks.GetChunkIndexByFirstDate(existingChunk.FirstDate)
            if (newChunkIndex !== CommonConstants.EMPTY_INDEX) {
                newEmptyChunks[newChunkIndex] = existingChunk
            }
        }
    }

    public StartDownloadingMap(aStartIndex: number = UNIX_DATE_MIN, aEndIndex: number = UNIX_DATE_SEC_OVER_MAX): void {
        const downloadTask = new TBarsMapDownloadTask(this, aStartIndex, aEndIndex)
        getGlobalDataDownloader().AddTaskIfNotInQueue(downloadTask)
    }

    public GetFirstAvailableDate(): TDateTime {
        if (this.fChunks.Count === 0 || this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            throw new DataNotDownloadedYetError(
                'GetFirstAvailableDate - No chunks available yet, cannot get first available date'
            )
        }

        return this.fChunks[0].FirstDate
    }

    public GetLastAvailableDate(): TDateTime {
        if (this.fChunks.Count === 0 || this.ChunkMapStatus !== TChunkMapStatus.cms_Loaded) {
            throw new DataNotDownloadedYetError(
                'GetLastAvailableDate - No chunks available yet, cannot get first available date'
            )
        }

        return this.fChunks.LastItem.LastPossibleDate
    }

    public GetMinMaxValuesForRange(currentTestingDate: TDateTime, targetDateInFuture: TDateTime): IMinMaxBidValues {
        const chunks = this.GetChunksForRangeDates(currentTestingDate, targetDateInFuture)
        const result = EMPTY_MIN_MAX_BID_VALUES
        for (const chunk of chunks) {
            if (chunk.Status !== TChunkStatus.cs_Loaded) {
                return EMPTY_MIN_MAX_BID_VALUES
            }
            const rangeStartForChunk = Math.max(chunk.FirstDate, currentTestingDate)
            const rangeEndForChunk = Math.min(chunk.LastPossibleDate, targetDateInFuture)
            const chunkMinMax = chunk.GetMinMaxValuesForRange(rangeStartForChunk, rangeEndForChunk)
            result.minBidPrice = Math.min(result.minBidPrice, chunkMinMax.minBidPrice)
            result.maxBidPrice = Math.max(result.maxBidPrice, chunkMinMax.maxBidPrice)
        }
        result.wasFound = true
        return result
    }

    public PreloadDataIfNecessary(startDate: TDateTime, endDate: TDateTime): void {
        //TODO: review this
        this.InitMapIfNecessary()
        if (!DateUtils.IsEmpty(startDate) && !DateUtils.IsEmpty(endDate)) {
            this.EnsureChunksForRangeLoadedOrLoading(startDate, endDate)
        }
    }
}
