import { TTickRecord } from './DataClasses/TTickRecord'
import { TSymbolData } from './SymbolData'

export class TTickRec {
    public SymbolName: string
    public tick: TTickRecord | null
    public symbolData: TSymbolData | null

    constructor() {
        this.SymbolName = ''
        this.tick = null
        this.symbolData = null
    }

    public IsEmpty(): boolean {
        return this.SymbolName === ''
    }
}
