import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TTradePositionType } from '../../ft_types/common/BasicClasses/BasicEnums'
import { TMyObjectList } from '../../ft_types/common/Common'
import { TOffsStringList } from '../../ft_types/common/OffsStringList'
import { TSymbolList } from '../../ft_types/data/SymbolList'
import { TTradePos } from './TradePosition'
import { IOrdersGroupedBySymbol } from './IOrdersGroupedBySymbol'
import ProcessingCoreUtils from '../ProcessingCoreUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TTradePositionsList extends TMyObjectList<TTradePos> {
    public SaveToList(list: TOffsStringList): void {
        for (let i = 0; i < this.Count; i++) {
            list.OpenSection('position')
            this.GetItem(i).SaveToList(list)
            list.CloseSection()
        }
    }

    public LoadFromList(SymList: TSymbolList, list: TOffsStringList): void {
        this.Clear()
        const list1 = new TOffsStringList()

        while (list.GetSection('position', list1)) {
            try {
                const pos = TTradePos.LoadFromOffsStringList(SymList, list1)
                this.Add(pos)
            } catch (error) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                    `[TTradePositionsList.LoadFromList] Cannot load position, reason: ${error}`
                )
            }
            list1.Clear()
        }
    }

    public getOrdersGroupedBySymbol(): IOrdersGroupedBySymbol {
        const groupedOrders: IOrdersGroupedBySymbol = {}

        for (let i = 0; i < this.Count; i++) {
            const currentOrder = this.GetItem(i)
            if (
                !ProcessingCoreUtils.isMarketOrder(currentOrder.PosType) &&
                !ProcessingCoreUtils.isPendingOrder(currentOrder.PosType)
            ) {
                //skip things like deposit/withdrawal
                continue
            }
            const symbolData = currentOrder.symbol
            if (!symbolData) {
                throw new StrangeError('Symbol data is not set for position')
            }
            const symbolName = symbolData.symbolInfo.SymbolName

            if (!groupedOrders[symbolName]) {
                groupedOrders[symbolName] = {
                    marketOrders: [],
                    pendingOrders: []
                }
            }

            if (ProcessingCoreUtils.isMarketOrder(currentOrder.PosType)) {
                groupedOrders[symbolName].marketOrders.push(currentOrder)
            } else if (ProcessingCoreUtils.isPendingOrder(currentOrder.PosType)) {
                groupedOrders[symbolName].pendingOrders.push(currentOrder)
            } else {
                throw new StrangeError('Unknown order type')
            }
        }

        return groupedOrders
    }

    public OpenMarketOrdersCount(): number {
        let result = 0
        for (let i = 0; i < this.Count; i++) {
            const item = this.GetItem(i)
            if (item.PosType === TTradePositionType.tp_Buy || item.PosType === TTradePositionType.tp_Sell) {
                result++
            }
        }
        return result
    }
}
