import GlobalChartsController from '../globals/GlobalChartsController'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { DelphiColors, TPenStyle } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TLineStyle } from '@fto/lib/drawing_interface/vclCanvas'
import { TPtHLine } from '@fto/lib/charting/paint_tools/SpecificTools/ptHLine'
import { TPaintToolStatus, TPaintToolType, TPointsArr } from '@fto/lib/charting/paint_tools/PaintToolsAuxiliaryClasses'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { ObservableTemplateItem, ObserverTemplate } from '@fto/chart_components/ObserverTemplate'
import { TMouseButton } from '@fto/lib/delphi_compatibility/DelphiFormsBuiltIns'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { StoplossValues, TakeprofitValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { TTradePositionType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { getTextWidth } from '@fto/lib/globals/GlobalTextSizeCache'
import { CustomCursorPointers } from '@fto/lib/ft_types/common/CursorPointers'
import { addModal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { OrderModel } from '@fto/lib/OrderModalClasses/OrderModel'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import { t } from 'i18next'
import { getOrderTypeLabel } from '@root/pages/ChartPage/components/Terminal/components/Table/components/Body/components/Row/components/CellRenderer/utils'

export interface OrderMarkerInfo {
    markerName: string
    price: number
    tDateTime: TDateTime
}

export enum OrderMarkerEvent {
    MARKER_MOVING,
    PICK_PRICE
}

export enum OrderMarkerType {
    STOP_LOSS,
    TAKE_PROFIT,
    AT_PRICE
}

export class OrderMarker extends TPtHLine {
    private orderMarkerInfo: OrderMarkerInfo
    public activeChartWindow: TChartWindow | null = null
    public owner: TMainChart
    public observableItem: ObservableTemplateItem<
        OrderMarkerEvent,
        OrderMarker,
        ObserverTemplate<OrderMarkerEvent, OrderMarker>
    >
    public orderMarkerType: OrderMarkerType
    public values: TakeprofitValues | StoplossValues | null = null
    public posType: TTradePositionType | null = null
    public isValid = true
    private isNeedShowModal = true
    private orderModel: OrderModel
    private previousPoints: TPointsArr | null = null
    private isOnPickerActive = false

    constructor(
        aChart: TMainChart,
        orderMarkerInfo: OrderMarkerInfo,
        orderMarkerType: OrderMarkerType,
        orderModel: OrderModel
    ) {
        super(aChart)
        this.owner = aChart
        GlobalOptions.Options.ToolsLinkNumber++
        this.LinkNumber = GlobalOptions.Options.ToolsLinkNumber
        this.orderMarkerInfo = orderMarkerInfo
        this.assign(this)
        this.AddPoint(0, 0)
        this.Points.LastItem.price = orderMarkerInfo.price
        this.Points.LastItem.time = orderMarkerInfo.tDateTime
        this.Timeframes.add(1)
        this.RText = orderMarkerInfo.markerName
        this.LineStyle = new TLineStyle(DelphiColors.clGreen, TPenStyle.psDashDot, 1)
        this.status = TPaintToolStatus.ts_Completed
        this.isNeededToCopyToOtherCharts = false
        this.isNeedToTransferBetweenCharts = false
        this.fToolType = TPaintToolType.tt_OrderMarker
        aChart.PaintTools.AddTool(this)

        this.observableItem = new ObservableTemplateItem<
            OrderMarkerEvent,
            OrderMarker,
            ObserverTemplate<OrderMarkerEvent, OrderMarker>
        >()

        this.orderMarkerType = orderMarkerType
        this.orderModel = orderModel
        this.CursorStyle = CustomCursorPointers.crPicker
    }

    public deleteMarkerByLinkNumber(): void {
        GlobalChartsController.Instance.OnDeleteLinkedTool(this.LinkNumber)
    }

    public attachObserver(observer: ObserverTemplate<OrderMarkerEvent, OrderMarker>): void {
        this.observableItem.attachObserver(observer)
    }

    public detachObserver(observer: ObserverTemplate<OrderMarkerEvent, OrderMarker>): void {
        this.observableItem.detachObserver(observer)
    }

    public OnMovePoint(index: number, time: TDateTime, price: number): void {
        if (!this.isOnPickerActive) {
            this.chart.ChartWindow.SetCursor(CustomCursorPointers.crPicker)
            removeModal(MODAL_NAMES.chart.orderModal)
        }

        super.OnMovePoint(index, time, price)
        this.observableItem.notify(OrderMarkerEvent.MARKER_MOVING, this)
    }

    public OnPicker(isInitialize = false): void {
        this.isOnPickerActive = true
        if (!this.previousPoints) {
            this.previousPoints = this.Points.clone()
        }

        GlobalChartsController.Instance.getActiveChart()?.pricePicker(this, isInitialize)
        if (isInitialize) {
            this.isOnPickerActive = false
        } else {
            this.isNeedShowModal = true
        }
    }

    public OnMouseDown(time: TDateTime, price: number, button: TMouseButton): void {
        super.OnMouseDown(time, price, button)
        this.isNeededToCopyToOtherCharts = false
        if (this.isNeedShowModal) {
            this.OpenOrderModal()
            this.isNeedShowModal = false
        }

        this.isOnPickerActive = false
    }

    public OnMouseUp(time: TDateTime, price: number, button: TMouseButton): void {
        super.OnMouseUp(time, price, button)

        if (!this.isOnPickerActive) {
            this.OpenOrderModal()
        }

        this.isOnPickerActive = false
    }

    public OnMouseMove(time: number, price: number, chart: TChart): void {
        if (!GlobalChartsController.Instance.IsOscUnderMouse()) {
            if (chart === this.owner) {
                super.OnMouseMove(time, price, chart)
            } else {
                const coordinates = this.owner.MouseToLocal()
                super.OnMouseMove(
                    this.owner.GetBarDateFromX(coordinates.x),
                    this.owner.GetPriceFromY(coordinates.y),
                    this.owner
                )
            }
            this.chart.ChartWindow.SetCursor(CustomCursorPointers.crPicker)
            this.observableItem.notify(OrderMarkerEvent.PICK_PRICE, this)
        }
    }

    public Paint(): void {
        if (this.orderMarkerType === OrderMarkerType.AT_PRICE) {
            this.PaintAtPrice()
            return
        }

        const canvasContext = this.chart.CanvasContext

        let baseColor: string

        if (this.isValid) {
            const chart = GlobalChartsController.Instance.getActiveChart()
            if (chart) {
                baseColor =
                    this.orderMarkerType === OrderMarkerType.STOP_LOSS
                        ? chart.ChartOptions.ColorScheme.StopLossColor
                        : chart.ChartOptions.ColorScheme.TakeProfitColor
            } else {
                baseColor = this.orderMarkerType === OrderMarkerType.STOP_LOSS ? '#E99537' : '#01987C'
            }
        } else {
            baseColor = '#98A2B3'
        }

        const boxY = this.chart.GetY(this.Points.LastItem.price)

        const labelText = this.orderMarkerType === OrderMarkerType.STOP_LOSS ? 'SL' : 'TP'
        let valueText = t('charting.graphTools.orderMarker.defaultValue')
        let pointsText = t('charting.graphTools.orderMarker.defaultPoints')

        if (this.values) {
            valueText =
                this.orderMarkerType === OrderMarkerType.STOP_LOSS
                    ? formatCurrency(this.values.usd * -1 || 0)
                    : formatCurrency(this.values.usd || 0)

            const pointsValue =
                this.orderMarkerType === OrderMarkerType.STOP_LOSS ? this.values.points * -1 : this.values.points

            pointsText = t('charting.graphTools.orderMarker.points', { count: Math.round(pointsValue) })
        }

        this.drawMarkerBox(canvasContext, labelText, valueText, pointsText, boxY, baseColor)
    }

    protected fillBackground(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = color

        ctx.fillRect(x, y, width, height)
        ctx.restore()
    }

    protected drawDashedDotLine(
        ctx: CanvasRenderingContext2D,
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        color: string
    ): void {
        ctx.save()
        ctx.strokeStyle = color
        ctx.lineWidth = 1
        ctx.setLineDash([15, 3, 3, 3])
        ctx.beginPath()
        ctx.moveTo(startX, startY)
        ctx.lineTo(endX, endY)
        ctx.stroke()
        ctx.restore()
    }

    protected drawRoundedRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        borderRadius: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = '#FFFFFF'
        ctx.strokeStyle = color
        ctx.lineWidth = 1
        ctx.shadowColor = 'rgba(0, 29, 67, 0.05)'
        ctx.shadowBlur = 2
        ctx.shadowOffsetY = 1

        ctx.beginPath()
        ctx.moveTo(x + borderRadius, y)
        ctx.lineTo(x + width - borderRadius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius)
        ctx.lineTo(x + width, y + height - borderRadius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height)
        ctx.lineTo(x + borderRadius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius)
        ctx.lineTo(x, y + borderRadius)
        ctx.quadraticCurveTo(x, y, x + borderRadius, y)
        ctx.closePath()
        ctx.fill()
        ctx.stroke()
        ctx.restore()
    }

    protected drawDragDots(ctx: CanvasRenderingContext2D, x: number, y: number, color: string): void {
        ctx.save()
        ctx.fillStyle = color
        const dotRadius = 1
        const dotSpacing = 3
        for (let i = 0; i < 6; i++) {
            const currentX = x + (i % 2) * dotSpacing
            const currentY = y + Math.floor(i / 2) * dotSpacing
            ctx.beginPath()
            ctx.arc(currentX, currentY, dotRadius, 0, Math.PI * 2)
            ctx.fill()
        }
        ctx.restore()
    }

    protected drawText(
        ctx: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        color: string,
        font: string
    ): void {
        ctx.save()
        ctx.fillStyle = color
        ctx.font = font
        ctx.textAlign = 'left'
        ctx.textBaseline = 'middle'
        ctx.fillText(text, x, y)
        ctx.restore()
    }

    protected drawSeparatorLine(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        height: number,
        color: string
    ): void {
        ctx.save()
        ctx.fillStyle = color
        ctx.fillRect(x, y, 1, height)
        ctx.restore()
    }

    private drawMarkerBox(
        ctx: CanvasRenderingContext2D,
        labelText: string,
        valueText: string,
        pointsText: string,
        y: number,
        baseColor: string
    ): void {
        const canvasWidth = ctx.canvas.width
        const x = canvasWidth / 5 // Position at 1/5 of the canvas width

        const boxPadding = 8
        const font = "12px 'Roboto Flex'"

        ctx.font = font

        // Measure text widths
        const labelTextWidth = ctx.measureText(labelText).width
        const valueTextWidth = ctx.measureText(valueText).width
        const pointsTextWidth = ctx.measureText(pointsText).width

        // Calculate box width
        const textWidth = labelTextWidth + valueTextWidth + pointsTextWidth + boxPadding
        const boxWidth = textWidth + boxPadding * 3
        const boxHeight = 16

        const lineLength = canvasWidth
        const borderRadius = 2

        const boxX = x - boxWidth / 2
        const boxY = y - boxHeight / 2

        // Draw dashed line
        this.drawDashedDotLine(ctx, 0, y, lineLength, y, baseColor)

        // Draw the order label box with rounded corners
        this.drawRoundedRect(ctx, boxX, boxY, boxWidth, boxHeight, borderRadius, baseColor)

        // Initial positions for texts
        const labelX = boxX + 10
        const separatorX1 = boxX + 10 + labelTextWidth + 5

        // Draw dots for drag
        this.drawDragDots(ctx, boxX + 2.5, y - 3, baseColor)

        // Draw title inside the box
        this.drawText(ctx, labelText, labelX, y, baseColor, font)

        // Draw separator line inside the box
        this.drawSeparatorLine(ctx, separatorX1, boxY + 1, 14, baseColor)

        // Draw value text inside the box
        const valueTextX = separatorX1 + 5
        this.drawText(ctx, valueText, valueTextX, y, baseColor, font)

        const separatorX2 = valueTextX + valueTextWidth + 5
        this.drawSeparatorLine(ctx, separatorX2, boxY + 1, 14, baseColor)
        this.drawText(ctx, pointsText, separatorX2 + 5, y, baseColor, font)
    }

    public PaintAtPrice(): void {
        // Define positions and sizes
        const canvasContext = this.chart.CanvasContext

        const canvasWidth = canvasContext.canvas.width
        const x = canvasWidth / 5 // Position at 1/5 of the canvas width
        const y = this.chart.GetY(this.Points.LastItem.price)
        let labelText = ''
        let secondWord = ''
        let baseColor = ''

        switch (this.posType) {
            case TTradePositionType.tp_BuyStop: {
                labelText = 'B'
                secondWord = 'Stop'
                baseColor = this.owner.ChartOptions.ColorScheme.BuyMarkerColor

                break
            }
            case TTradePositionType.tp_SellStop: {
                labelText = 'S'
                secondWord = 'Stop'
                baseColor = this.owner.ChartOptions.ColorScheme.SellMarkerColor

                break
            }
            case TTradePositionType.tp_BuyLimit: {
                labelText = 'B'
                secondWord = 'Limit'
                baseColor = this.owner.ChartOptions.ColorScheme.BuyMarkerColor

                break
            }
            case TTradePositionType.tp_SellLimit: {
                labelText = 'S'
                secondWord = 'Limit'
                baseColor = this.owner.ChartOptions.ColorScheme.SellMarkerColor

                break
            }
            // No default
        }

        if (!this.isValid) {
            baseColor = '#98A2B3'
        }

        const boxPadding = 8
        const font = "12px 'Roboto Flex'"

        canvasContext.font = font

        // Measure text widths
        const labelTextWidth = getTextWidth(canvasContext, labelText, font)
        const secondWordWidth = getTextWidth(canvasContext, secondWord, font)

        // Calculate box width
        const textWidth = labelTextWidth + secondWordWidth
        const boxWidth = textWidth + boxPadding * 2
        const boxHeight = 16

        const lineLength = canvasWidth
        const borderRadius = 2

        const boxX = x - boxWidth / 2
        const boxY = y - boxHeight / 2

        // Draw dashed line
        this.drawDashedDotLine(canvasContext, 0, y, lineLength, y, baseColor)

        // Draw the order label box with rounded corners
        this.drawRoundedRect(canvasContext, boxX, boxY, boxWidth, boxHeight, borderRadius, baseColor)

        // Initial positions for texts
        const labelX = boxX + 3
        const separatorX1 = boxX + 3 + labelTextWidth + 5

        // Fill background of the box to first separator line
        this.fillBackground(canvasContext, boxX, boxY, separatorX1 - boxX, boxHeight, baseColor)

        // Draw title inside the box
        this.drawText(canvasContext, labelText, labelX, y, '#ffffff', font)

        // Draw separator line inside the box
        this.drawSeparatorLine(canvasContext, separatorX1, boxY + 1, 14, baseColor)

        this.drawText(canvasContext, secondWord, separatorX1 + 5, y, baseColor, font)
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public ExportToDialog(): void {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public ImportFromDialog(): void {}
    public EditTool(): boolean {
        return false
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public Hide(): void {}

    public onCancelPricePick(): void {
        this.isNeedShowModal = true
        if (this.previousPoints) {
            this.fPoints = this.previousPoints
            this.previousPoints = null
            this.observableItem.notify(OrderMarkerEvent.PICK_PRICE, this)
        }

        this.OpenOrderModal()
        this.isNeedShowModal = false

        this.ReportCancelOfWork()
        this.activeChartWindow?.SetNormalMode()
    }

    private OpenOrderModal() {
        if (!!this.orderModel.orderOnEditing) {
            const id = this.orderModel.orderOnEditing!.id
            const type = this.orderModel.orderOnEditing!.type

            addModal(MODAL_NAMES.chart.orderModal, {
                isEdit: true,
                headerLabel: t('orders.modal.editHeader', {
                    id: id,
                    orderType: t(getOrderTypeLabel(type))
                })
            })
        } else {
            addModal(MODAL_NAMES.chart.orderModal, {
                isEdit: false
            })
        }
    }
}
