import StrangeError from '../common/common_errors/StrangeError'
import {
    TOperationType,
    TPendingOpType,
    TSymbolType,
    TTradePositionType
} from '../ft_types/common/BasicClasses/BasicEnums'
import { TSymbolData } from '../ft_types/data/SymbolData'
import { TSymbolCalcType } from '../ft_types/data/DataEnums'
import { NotImplementedError } from '../utils/common_utils'
import GlobalSymbolList from '../globals/GlobalSymbolList'
import { TTradePos } from './TradePositionClasses/TradePosition'
import { IMinMaxAllValues, IMinMaxBidValues } from '../ft_types/data/DataUtils/IMinMaxValues'
import { IOrdersByType } from './TradePositionClasses/IOrdersByType'

export default class ProcessingCoreUtils {
    public static StrPosType(OpType: TOperationType | TPendingOpType | TTradePositionType): string {
        if (this.isOperationType(OpType)) {
            switch (OpType) {
                case TOperationType.ot_Sell: {
                    return 'sell'
                }
                default: {
                    return 'buy'
                }
            }
        } else if (this.isPendingOpType(OpType)) {
            switch (OpType) {
                case TPendingOpType.pt_BuyLimit: {
                    return 'buy limit'
                }
                case TPendingOpType.pt_SellLimit: {
                    return 'sell limit'
                }
                case TPendingOpType.pt_BuyStop: {
                    return 'buy stop'
                }
                default: {
                    return 'sell stop'
                }
            }
        } else if (this.isTradePositionType(OpType)) {
            switch (OpType) {
                case TTradePositionType.tp_Sell: {
                    return 'sell'
                }
                case TTradePositionType.tp_Buy: {
                    return 'buy'
                }
                case TTradePositionType.tp_BuyLimit: {
                    return 'buy limit'
                }
                case TTradePositionType.tp_SellLimit: {
                    return 'sell limit'
                }
                case TTradePositionType.tp_BuyStop: {
                    return 'buy stop'
                }
                case TTradePositionType.tp_SellStop: {
                    return 'sell stop'
                }
                case TTradePositionType.tp_Deposit: {
                    return 'deposit'
                }
                case TTradePositionType.tp_Withdrawal: {
                    return 'withdrawal'
                }
                case TTradePositionType.tp_Credit: {
                    return 'credit'
                }
                default: {
                    return ''
                }
            }
        } else {
            throw new StrangeError('Unknown operation type')
        }
    }

    // Improved type guard functions using exhaustive checks
    private static isOperationType(opType: any): opType is TOperationType {
        return Object.values(TOperationType).includes(opType)
    }

    private static isPendingOpType(opType: any): opType is TPendingOpType {
        return Object.values(TPendingOpType).includes(opType)
    }

    private static isTradePositionType(opType: any): opType is TTradePositionType {
        return Object.values(TTradePositionType).includes(opType)
    }

    public static GetCalculationType(symbol: TSymbolData): TSymbolCalcType {
        switch (symbol.symbolInfo.s_type) {
            case TSymbolType.st_CurrencyPair: {
                return this.GetCalculationTypeForForex(symbol)
            }
            case TSymbolType.st_Crypto: {
                throw new NotImplementedError('Index calculation type not implemented.')
            }
            case TSymbolType.st_Stock: {
                throw new NotImplementedError('Stock calculation type not implemented.')
            }
            default: {
                throw new StrangeError('Unknown symbol type')
            }
        }
    }

    public static GetCalculationTypeForForex(symbol: TSymbolData): TSymbolCalcType {
        if (symbol.symbolInfo.SymbolName.startsWith('USD')) {
            return TSymbolCalcType.sc_InvertedUSD_USDxxx
        } else if (symbol.symbolInfo.SymbolName.endsWith('USD')) {
            return TSymbolCalcType.sc_Normal_xxxUSD
        } else {
            return TSymbolCalcType.sc_Cross
        }
    }

    public static GetSymbolForConversionToUSD(
        symbol: TSymbolData,
        force = false
    ): {
        symbolName: string
        isUSDBaseCurrency: boolean
        isNeedConversionToSymbolQuoteCurrency: boolean
    } {
        const firstCurrency = symbol.symbolInfo.SymbolName.slice(0, 3)
        const secondCurrency = symbol.symbolInfo.SymbolName.slice(3, 6)

        if (symbol.symbolInfo.group === 'Crosses' || symbol.symbolInfo.group === 'Exotic' || force) {
            const secondCurrencyParams = GlobalSymbolList.SymbolList.CheckPossibleCrossPairs(secondCurrency)
            if (secondCurrencyParams) {
                return {
                    symbolName: secondCurrencyParams.symbolName,
                    isUSDBaseCurrency: secondCurrencyParams.isUSDBaseCurrency,
                    isNeedConversionToSymbolQuoteCurrency: true
                }
            }

            const firstCurrencyParams = GlobalSymbolList.SymbolList.CheckPossibleCrossPairs(firstCurrency)
            if (firstCurrencyParams) {
                return {
                    symbolName: firstCurrencyParams.symbolName,
                    isUSDBaseCurrency: firstCurrencyParams.isUSDBaseCurrency,
                    isNeedConversionToSymbolQuoteCurrency: false
                }
            }

            throw new StrangeError(`Symbol ${symbol.symbolInfo.SymbolName} is not correct for conversion to USD.`)
        }

        return {
            symbolName: symbol.symbolInfo.SymbolName,
            isUSDBaseCurrency: false,
            isNeedConversionToSymbolQuoteCurrency: false
        }
    }

    public static canPendingOrdersBeTriggered(pendingOrders: TTradePos[], minMaxValues: IMinMaxBidValues): boolean {
        for (const order of pendingOrders) {
            if (this.canSinglePendingBeTriggered(order, minMaxValues)) {
                return true
            }
        }
        return false
    }

    private static canSinglePendingBeTriggered(order: TTradePos, minMaxValues: IMinMaxBidValues): boolean {
        const symbolData = order.symbol
        if (!symbolData) {
            throw new StrangeError(
                'Symbol data is not set for a pending order, this is only ok for Deposits and Withdrawals'
            )
        }
        switch (order.PosType) {
            case TTradePositionType.tp_BuyLimit:
            case TTradePositionType.tp_BuyStop: {
                const maxAskPrice = symbolData.GetApproximateAskFromBid(minMaxValues.maxBidPrice)
                const minAskPrice = symbolData.GetApproximateAskFromBid(minMaxValues.minBidPrice)
                const currentAskPrice = symbolData.ask
                if (
                    (order.tpos.OpenPrice >= currentAskPrice && order.tpos.OpenPrice <= maxAskPrice) ||
                    (order.tpos.OpenPrice <= currentAskPrice && order.tpos.OpenPrice >= minAskPrice)
                ) {
                    return true
                }
                break
            }
            case TTradePositionType.tp_SellStop:
            case TTradePositionType.tp_SellLimit: {
                const maxBidPrice = minMaxValues.maxBidPrice
                const minBidPrice = minMaxValues.minBidPrice
                const currentBidPrice = symbolData.bid
                if (
                    (order.tpos.OpenPrice <= currentBidPrice && order.tpos.OpenPrice >= minBidPrice) ||
                    (order.tpos.OpenPrice >= currentBidPrice && order.tpos.OpenPrice <= maxBidPrice)
                ) {
                    return true
                }
                break
            }
            default: {
                throw new StrangeError('Unexpected order type in canPendingBeTriggered')
            }
        }
        return false
    }

    public static canSLBeHit(
        symbolData: TSymbolData,
        marketOrdersForSymbol: TTradePos[],
        minMaxValues: IMinMaxBidValues
    ): boolean {
        for (const order of marketOrdersForSymbol) {
            switch (order.PosType) {
                case TTradePositionType.tp_Buy: {
                    //buy order is closed by bid price
                    const minBidPriceForSymbol = minMaxValues.minBidPrice
                    const maxBidPriceForSymbol = minMaxValues.maxBidPrice
                    const currentBidPrice = symbolData.bid
                    if (
                        (order.tpos.StopLoss >= currentBidPrice && order.tpos.StopLoss <= maxBidPriceForSymbol) ||
                        (order.tpos.StopLoss < currentBidPrice && order.tpos.StopLoss >= minBidPriceForSymbol)
                    ) {
                        return true
                    }
                    break
                }
                case TTradePositionType.tp_Sell: {
                    //sell order is closed by ask price
                    const maxAskPriceForSymbol = symbolData.GetApproximateAskFromBid(minMaxValues.maxBidPrice)
                    const minAskPriceForSymbol = symbolData.GetApproximateAskFromBid(minMaxValues.minBidPrice)
                    const currentAskPrice = symbolData.ask
                    if (
                        (order.tpos.StopLoss <= currentAskPrice && order.tpos.StopLoss >= minAskPriceForSymbol) ||
                        (order.tpos.StopLoss > currentAskPrice && order.tpos.StopLoss <= maxAskPriceForSymbol)
                    ) {
                        return true
                    }
                    break
                }
                default: {
                    throw new StrangeError('Unexpected order type in canSLBeHit')
                }
            }
        }
        return false
    }

    public static isPendingOrder(posType: TTradePositionType): boolean {
        return [
            TTradePositionType.tp_BuyLimit,
            TTradePositionType.tp_BuyStop,
            TTradePositionType.tp_SellLimit,
            TTradePositionType.tp_SellStop
        ].includes(posType)
    }

    public static isMarketOrder(posType: TTradePositionType): boolean {
        return [TTradePositionType.tp_Sell, TTradePositionType.tp_Buy].includes(posType)
    }

    public static getMaxPossibleDrawdownForSymbol(
        symbolData: TSymbolData,
        ordersForSymbol: IOrdersByType,
        minMaxValues: IMinMaxAllValues,
        checkPendingOrders: boolean
    ): number {
        const maxPossibleLossFromMarketOrders = this.getMaxPossibleDrawdownFromMarketOrders(
            symbolData,
            ordersForSymbol.marketOrders,
            minMaxValues
        )

        let maxPossibleLossFromPendingOrders = 0

        if (checkPendingOrders) {
            maxPossibleLossFromPendingOrders = this.getMaxPossibleDrawdownFromPendingOrders(
                symbolData,
                ordersForSymbol.pendingOrders,
                minMaxValues
            )
        }

        return maxPossibleLossFromMarketOrders + maxPossibleLossFromPendingOrders
    }

    private static getMaxPossibleDrawdownFromMarketOrders(
        symbolData: TSymbolData,
        marketOrders: TTradePos[],
        minMaxValues: IMinMaxAllValues
    ): number {
        let totalDrawdown = 0

        for (const order of marketOrders) {
            const orderDrawdown = ProcessingCoreUtils.getMaxPossibleDrawdownForOrder_negativeOr0(
                order,
                minMaxValues,
                symbolData
            )

            // Only accumulate negative values
            if (orderDrawdown < 0) {
                totalDrawdown += orderDrawdown
            }
        }

        return totalDrawdown
    }

    private static isLongOrder(posType: TTradePositionType): boolean {
        return [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyLimit, TTradePositionType.tp_BuyStop].includes(
            posType
        )
    }

    //should return 0 or negative value
    private static getMaxPossibleDrawdownForOrder_negativeOr0(
        order: TTradePos,
        minMaxValues: IMinMaxAllValues,
        symbolData: TSymbolData
    ) {
        const openPrice = order.tpos.OpenPrice
        const stopLoss = order.tpos.StopLoss
        let potentialClosePrice: number

        if (this.isLongOrder(order.PosType)) {
            // For buy orders:
            // Drawdown based on minimum bid price (since buy orders close on bid price)
            const edgeOfMarket = minMaxValues.minBidPrice

            potentialClosePrice = stopLoss ? Math.max(stopLoss, edgeOfMarket) : edgeOfMarket
        } else if (this.isShortOrder(order.PosType)) {
            // For sell orders:
            // Drawdown based on maximum ask price (since sell orders close on ask price)
            const edgeOfMarket = symbolData.GetApproximateAskFromBid(minMaxValues.maxBidPrice)

            potentialClosePrice = stopLoss ? Math.min(stopLoss, edgeOfMarket) : edgeOfMarket
        } else {
            throw new StrangeError('Unexpected order type in getMaxPossibleDrawdownFromMarketOrders')
        }

        const maxDrawdownForOrder = order.calculateProfitForClosePrice(potentialClosePrice)
        // if the order is in profit, return 0, if in loss, return the loss
        return Math.min(maxDrawdownForOrder, 0)
    }

    private static isShortOrder(PosType: TTradePositionType): boolean {
        return [TTradePositionType.tp_Sell, TTradePositionType.tp_SellLimit, TTradePositionType.tp_SellStop].includes(
            PosType
        )
    }

    public static canMarginCallHappen(balance: number, maxPossibleDrawdownAsNegativeValue: number): boolean {
        const balanceReserveInPercentage = 10
        const balanceRequirementAfterReserve = balance * (1 - balanceReserveInPercentage / 100)

        const maxPossibleDrawdownAsPositiveValue = Math.abs(maxPossibleDrawdownAsNegativeValue)

        if (balanceRequirementAfterReserve <= maxPossibleDrawdownAsPositiveValue) {
            return true
        }
        return false
    }

    private static getMaxPossibleDrawdownFromPendingOrders(
        symbolData: TSymbolData,
        pendingOrders: TTradePos[],
        minMaxValues: IMinMaxAllValues
    ): number {
        let totalDrawdown = 0

        for (const order of pendingOrders) {
            if (this.canSinglePendingBeTriggered(order, minMaxValues)) {
                totalDrawdown += this.getMaxPossibleDrawdownForOrder_negativeOr0(order, minMaxValues, symbolData)
            }
        }

        return totalDrawdown
    }
}
