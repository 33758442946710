export interface IMinMaxBidValues {
    minBidPrice: number
    maxBidPrice: number //bars are built from bid prices, hence min and max bid prices
    wasFound: boolean
}

export interface IMinMaxAllValues extends IMinMaxBidValues {
    minAskPrice: number
    maxAskPrice: number
}

export const EMPTY_MIN_MAX_BID_VALUES: IMinMaxBidValues = {
    maxBidPrice: Number.MIN_VALUE,
    minBidPrice: Number.MAX_VALUE,
    wasFound: false
}

export const EMPTY_MIN_MAX_ALL_VALUES: IMinMaxAllValues = {
    ...EMPTY_MIN_MAX_BID_VALUES,
    maxAskPrice: Number.MIN_VALUE,
    minAskPrice: Number.MAX_VALUE
}
