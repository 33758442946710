import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'
import {
    ChartJSON,
    HistoryJSON,
    IndicatorsJSON,
    OscIndicatorsJSON,
    PaintToolsJSON,
    ProjectJSON,
    ProjectJSONfromServer,
    ProjectJSONTestingSettings
} from '@fto/lib/ProjectAdapter/Types'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { TOffsStringList } from '@fto/lib/ft_types/common/OffsStringList'
import { TDataArrayEvents } from '@fto/lib/ft_types/data/data_downloading/DownloadRelatedEnums'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import ChartSettingsStore from '@fto/lib/store/chartSettings'
import ProjectStore from '@fto/lib/store/projectStore'
import { EducationProcessor } from '@fto/lib/Education/EducationProcessor'
import { NewsDownloader } from '@fto/lib/News/NewsDonwloader'
import { CountryChartImages } from '@fto/countries'
import GlobalFontManager from '@fto/lib/globals/GlobalFontManager'
import { GlobalNewsController } from '@fto/lib/News/GlobalNewsController'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import StrangeError from '../common/common_errors/StrangeError'
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import DataNotDownloadedYetError from '../ft_types/data/data_errors/DataUnavailableError'
import { DebugUtils } from '../utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import CommonConstants from '../ft_types/common/CommonConstants'
import { fetchAllTemplates } from '@root/utils/api'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import { CustomTimeFrame } from '@fto/lib/store/timeframe/types'
import TimeframeStore from '@fto/lib/store/timeframe'
import CommonUtils from '../ft_types/common/BasicClasses/CommonUtils'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { TTradePositionType } from '../ft_types/common/BasicClasses/BasicEnums'

interface ILoadedTestingSettings {
    SliderPosition: number
    TestingAccuracyInMinutes: number
}

const DEFAULT_TESTING_SETTINGS: ILoadedTestingSettings = {
    SliderPosition: 9,
    TestingAccuracyInMinutes: 0
}
export class GlobalProjectJSONAdapter {
    public projectId: string | null = null
    public isPreset = false
    public projectName: string | null = null
    private downloadedProjectJSON: ProjectJSONfromServer | null = null
    public isProjectLoaded = false

    //TODO: probably it would be better to remove this, why do we need a baseSymbol if we always should have a project with at least one symbol?
    private baseSymbol = 'EURUSD'
    private layoutSettings: ChartInfoType = {
        count: 1,
        layout: 'one-chart',
        symbol: this.baseSymbol
    }

    public TestingsSettings = DEFAULT_TESTING_SETTINGS

    private symbols: string[] = []
    private isAllSymbolsInfoLoaded = false
    private countOfChartsLoaded = 0
    private countOfChartsToLoad = 0
    private CustomTimeframes: CustomTimeFrame[] = []

    private static instance: GlobalProjectJSONAdapter

    public static get Instance(): GlobalProjectJSONAdapter {
        if (!GlobalProjectJSONAdapter.instance) {
            GlobalProjectJSONAdapter.instance = new GlobalProjectJSONAdapter()

            // eslint-disable-next-line promise/always-return
            GlobalFontManager.Instance.loadFonts()
                .then(() => {
                    GlobalChartsController.Instance.onFontsLoaded()
                })
                .catch((error) => {
                    throw new StrangeError('Error loading fonts:', error)
                })
        }

        return GlobalProjectJSONAdapter.instance
    }

    public Reset(): void {
        this.projectId = null
        this.projectName = null
        this.downloadedProjectJSON = null
        this.isProjectLoaded = false
        this.baseSymbol = 'EURUSD'
        this.layoutSettings = {
            count: 1,
            layout: 'one-chart',
            symbol: this.baseSymbol
        }
        this.countOfChartsToLoad = 0
        this.countOfChartsLoaded = 0

        const { setSettings } = ChartSettingsStore

        try {
            setSettings((settings) => ({
                ...settings,
                isPlaying: false
            }))

            GlobalTestingManager.TestingManager.StopTestingIfPlaying()
        } catch (error) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Error while resetting project', error)
        }

        GlobalProcessingCore.ProcessingCore.Reset()
        GlobalProjectInfo.initEmptyProjectInfo()
        GlobalSymbolList.Destroy()
        SyncProjectManager.Instance.destroy()
        GlobalChartsController.Instance.reset()
        GlobalNewsController.Instance.resetFilters()
        GlobalOptions.Init()
    }

    public setDownloadedJSON(json: ProjectJSONfromServer): void {
        this.downloadedProjectJSON = json
        this.projectName = json.ProjectName
    }

    public getBaseSymbolName(): string {
        return this.baseSymbol
    }

    public Initialize(projectId: string | null = null, userId = ''): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Loading,
            `GlobalProjectJSONAdapter - Initializing project with id ${projectId}`
        )

        const { updateData } = ProjectStore
        this.Reset()

        updateData((prev) => ({ ...prev, isLoading: true, isError: false }))

        let attempts = 0
        const maxAttempts = 3

        if (this.areAllSymbolsLoaded()) {
            //TODO: does it make sense to do it here before we even asked to download the project??
            GlobalNewsController.Instance.filterNews()
        }

        const downloadProject = () => {
            SyncProjectManager.Instance.DownloadProject(projectId)
                .then((data) => {
                    this.isPreset = !!(userId && userId !== data.UserId)

                    for (const symbol of this.getAllSymbolsFromProject(data)) {
                        GlobalSymbolList.SymbolList.CreateSymbol(symbol, false)
                    }
                    GlobalSymbolList.SymbolList.Events.on(
                        TDataArrayEvents.de_SymbolInfoLoaded,
                        this.boundOnSymbolInfoLoaded
                    )

                    GlobalSymbolList.SymbolList.Events.on(TDataArrayEvents.de_SeekCompleted, this.boundOnSeekCompleted)

                    this.projectId = projectId
                    this.downloadedProjectJSON = data
                })
                .catch((error) => {
                    StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Error while downloading project', error)
                    attempts++
                    if (attempts < maxAttempts) {
                        DebugUtils.log(`Retrying download operation. Attempt ${attempts + 1}`)
                        setTimeout(downloadProject, 1000) // Wait 1 second before retrying
                    } else {
                        // showErrorToast({ message: 'Oooops, something went wrong' })
                        DebugUtils.error('Failed to download project after 3 attempts:', error)
                        updateData((prev) => ({ ...prev, isLoading: false, isError: true }))
                    }
                })
        }

        downloadProject()
        CountryChartImages.getInstance() // Preload images
    }

    private areAllSymbolsLoaded(): boolean {
        for (const symbol of GlobalSymbolList.SymbolList.Symbols) {
            if (!symbol.symbolInfo.isLoadedFromServer) {
                return false
            }
        }
        return true
    }

    private boundOnSymbolInfoLoaded = this.onSymbolInfoLoaded.bind(this)
    private onSymbolInfoLoaded() {
        if (!NewsDownloader.Instance.isDownloading) {
            const newsSymbols = ['AUD', 'JPY', 'CNY', 'EUR', 'CHF', 'GBP', 'USD', 'CAD', 'NZD'] // All possible currencies in server for the news

            NewsDownloader.Instance.downloadNews(newsSymbols)
        }

        if (!this.areAllSymbolsLoaded()) {
            //we can only go further when all the symbols are ready
            return
        }
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'All symbols are loaded')

        const { updateData } = ProjectStore

        if (this.downloadedProjectJSON) {
            DebugUtils.logTopic(ELoggingTopics.lt_Loading, "Project's JSON is downloaded, starting to load it")
            this.restoreWholeProjectFromJSON(this.downloadedProjectJSON)
            if (this.downloadedProjectJSON.HomeWorks && this.downloadedProjectJSON.HomeWorks.length > 0) {
                EducationProcessor.Instance.startHomework(JSON.parse(this.downloadedProjectJSON.HomeWorks))
            }
            this.countOfChartsToLoad = GlobalChartsController.Instance.chartWindowsLoadedFromProject.length
            DebugUtils.logTopic(ELoggingTopics.lt_Loading, `Count of charts to load: ${this.countOfChartsToLoad}`)
            updateData((prev) => ({ ...prev, isLoading: false, isError: false }))
            this.processGlobalsLoadings()
            GlobalNewsController.Instance.filterNews()
        } else {
            throw new StrangeError("Unexpected error: downloadedProjectJSON doesn't exist")
        }

        if (!this.isPreset) {
            SyncProjectManager.Instance.start()
        }

        GlobalSymbolList.SymbolList.Events.off(TDataArrayEvents.de_SymbolInfoLoaded, this.boundOnSymbolInfoLoaded)
    }

    private boundOnSeekCompleted = this.onSeekCompleted.bind(this)
    private onSeekCompleted() {
        GlobalSymbolList.SymbolList.Events.off(TDataArrayEvents.de_SeekCompleted, this.boundOnSeekCompleted)
        this.restoreOrdersFromJSON()
    }

    private getAllSymbolsFromProject(data: ProjectJSONfromServer): string[] {
        if (!data) {
            return []
        }

        const charts = this.GetChartsJSON()
        this.symbols = [...data.Symbols]
        const result = [...data.Symbols]

        if (!charts) {
            // it`s ok, because we only create new project
            return result
        }

        for (const chart in charts) {
            const jsonChartObject = charts[chart]
            if (!result.includes(jsonChartObject.symbolName)) {
                result.push(jsonChartObject.symbolName)
            }
        }

        return result
    }

    private packChartsToJSON(): string {
        //FIXME: what to do if some charts are not initialized yet???
        const _allCharts = GlobalChartsController.Instance.getAllCharts()
        if (_allCharts.length > CommonConstants.MAX_NUMBER_OF_CHARTS) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Too many charts to save, the number of charts is: ${_allCharts.length}`
            )
        }

        const slicedCharts = _allCharts.slice(0, CommonConstants.MAX_NUMBER_OF_CHARTS)

        const layoutSettings = this.getLayoutSettings()

        const chartsJSONWithLayoutSettings: [ChartInfoType, ChartJSON[]] = [layoutSettings, []]

        for (const chartWindow of slicedCharts) {
            if (!chartWindow.isDataLoaded) {
                throw new DataNotDownloadedYetError('Data is not downloaded yet, cannot save project')
            }
            if (chartWindow.isShown) {
                const chartJSON = chartWindow.toJson()
                chartsJSONWithLayoutSettings[1].push(chartJSON)
            }
        }

        this.isLayoutSettingsValid(slicedCharts, layoutSettings)

        return JSON.stringify(chartsJSONWithLayoutSettings)
    }

    private getOrderInfoJSON(): string {
        return GlobalProcessingCore.ProcessingCore.toJSON().SaveToString()
    }

    public setLayoutSettings(layoutSettings: ChartInfoType): void {
        this.layoutSettings = layoutSettings
    }

    public getLayoutSettings(): ChartInfoType {
        return this.layoutSettings
    }

    public getTestingSettings() {
        return this.TestingsSettings
    }

    public getProjectLastUse(): number {
        return this.downloadedProjectJSON?.LastUse || 0
    }

    public getProjectInfoForStatistics() {
        try {
            const pairs = GlobalChartsController.Instance.getAllCharts().map(
                (chartWin) => chartWin.SymbolData.symbolInfo.SymbolName
            )
            return {
                pairs: pairs,
                project_id: this.projectId,
                project_name: this.projectName,
                deposit: this.downloadedProjectJSON?.Deposit,
                set_end_date: this.downloadedProjectJSON?.EndDate === 0 ? 'no' : 'yes',
                start_date: this.downloadedProjectJSON?.StartDate,
                end_date: this.downloadedProjectJSON?.EndDate,
                forward_testing: this.downloadedProjectJSON?.ForwardMode
            }
        } catch (error) {
            //silence this error since it is not critical
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                'Error while getting project info for statistics',
                error
            )
            return {
                project_id: 'unknown'
            }
        }
    }

    public getJSON(): ProjectJSON {
        const processingCore = GlobalProcessingCore.ProcessingCore
        if (!this.projectName) {
            throw new StrangeError('Project name is not set')
        }

        const result = {
            ProjectName: this.projectName || 'default',
            LastTickTime: DateUtils.toUnixTimeMilliseconds(
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(true)
            ),
            TotalProfitAndLoss: processingCore.getTotalProfitAndLoss(),
            Chart: this.packChartsToJSON(),
            DaylightSavingTime: GlobalTimezoneDSTController.Instance.getServerFormatDST(),
            TimeZone: GlobalTimezoneDSTController.Instance.getServerFormatTimezone(),
            Statistics: JSON.stringify(GlobalProcessingCore.ProcessingCore.GetStatistics()),
            ProfitChart: [
                {
                    Date: 123,
                    Balance: 123.4,
                    Equity: 234.5,
                    Margin: 345.6,
                    Drawdown: 456.7
                }
            ],
            CurrentOrders: this.getOrderInfoJSON(),
            OrdersHistory: this.getOnlyNewHistory(),
            Notes: '123',
            TestingSettings: this.getTestingSettings(),
            HomeWorks: this.downloadedProjectJSON?.HomeWorks || '',
            Symbols: this.symbols,
            CustomTimeframes: this.CustomTimeframes || this.downloadedProjectJSON?.CustomTimeframes || []
        }

        this.__validateJSON_beforeSaving(result)

        return result
    }

    private __validateJSON_beforeSaving(json: ProjectJSON) {
        if (!json.Chart) {
            throw new StrangeError('Cannot save project, chart is not set')
        }
        this.__validateOrdersBeforeSaving(json)
    }

    private __validateOrdersBeforeSaving(json: ProjectJSON) {
        const currentTimeInProject = json.LastTickTime
        if (json.CurrentOrders) {
            for (const order of json.OrdersHistory) {
                if (order.PosType === TTradePositionType.tp_Deposit) {
                    //TODO: remove this when we fix a bug with initial deposit (if we start a project from weekend, the initial deposit will be on Monday meaning in the future)
                    continue
                }
                if (order.OpenTime > currentTimeInProject) {
                    throw new StrangeError('Cannot save project, order close time is in the future')
                }
                if (order.CloseTime > currentTimeInProject) {
                    throw new StrangeError('Cannot save project, order close time is in the future')
                }
            }
        }
    }

    private paintToolsFromJSON(tools: PaintToolsJSON, chartWindow: TChartWindow) {
        chartWindow.MainChart.PaintTools.fromJSON(tools, chartWindow)
    }

    private indicatorsFromJSON(indicators: IndicatorsJSON, chartWindow: TChartWindow) {
        chartWindow.MainChart.indicators.fromJSON(indicators, chartWindow)
    }

    private oscWinAdd(oscWin: OscIndicatorsJSON, chartWindow: TChartWindow) {
        chartWindow.oscWinsFromJSON(oscWin)
    }

    private setLastTickTime(timeMSUnixTime: number) {
        const lastTickTime = DateUtils.fromUnixTimeMilliseconds(timeMSUnixTime)
        //lastTickTime will be set during seek
        GlobalSymbolList.SymbolList.Seek(lastTickTime)
        GlobalProcessingCore.ProcessingCore.CurrTime = lastTickTime
        //we will do that during Seek: GlobalSymbolList.SymbolList.UpdateChunksInfo(lastTickTime)
    }

    private getCorrespondingLayoutSettings(symbolsCount: number): Partial<ChartInfoType> {
        switch (symbolsCount) {
            case 1: {
                return {
                    count: 1,
                    layout: 'one-chart'
                }
            }
            case 2: {
                return {
                    count: 2,
                    layout: 'two-charts-horizontally'
                }
            }
            case 3: {
                return {
                    count: 3,
                    layout: 'three-charts-horizontally'
                }
            }
            case 4: {
                return {
                    count: 4,
                    layout: 'four-charts-quadrant'
                }
            }
            // eslint-disable-next-line sonarjs/no-duplicated-branches
            default: {
                return {
                    count: 1,
                    layout: 'one-chart'
                }
            }
        }
    }

    public restoreWholeProjectFromJSON(projectJSON: ProjectJSONfromServer): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, `Loading whole project from JSON ${projectJSON.ProjectName}`)
        this.projectName = projectJSON.ProjectName
        //FIXME: dirty hack, remove this after testing:
        if (projectJSON.ProjectName.includes('basicData')) {
            CommonUtils.IsBasicDataSubscription = true
        }

        GlobalProjectInfo.initEmptyProjectInfo()

        GlobalTimezoneDSTController.Instance.updateDstFromServer(projectJSON.DaylightSavingTime)
        GlobalTimezoneDSTController.Instance.updateTimezoneFromServer(projectJSON.TimeZone)

        GlobalProjectInfo.ProjectInfo.StartDate = DateUtils.fromUnixTimeMilliseconds(projectJSON.StartDate)

        if (projectJSON.LastTickTime) {
            this.setLastTickTime(projectJSON.LastTickTime)
        } else {
            const innerLibTime = GlobalTimezoneDSTController.Instance.convertToInnerlibUnixMilisecondsByTimezoneAndDst(
                projectJSON.StartDate
            )
            const diff = projectJSON.StartDate - innerLibTime
            this.setLastTickTime(projectJSON.StartDate - diff)
        }

        this.baseSymbol = projectJSON.Symbols[0]

        const chartsJSON = this.GetChartsJSON()
        let chartInstances = []

        if (chartsJSON && chartsJSON.length > 0) {
            chartInstances = this.restoreChartsFromJSON(chartsJSON)
            TimeframeStore.setTimeframe(chartsJSON[0].Timeframe)
        } else {
            //we need this case for first opening of the project when we do not have any charts but we know the symbols
            chartInstances = this.InitDefaultCharts(projectJSON.Symbols)

            GlobalProcessingCore.ProcessingCore.InitialDeposit(
                DateUtils.fromUnixTimeMilliseconds(projectJSON.StartDate),
                projectJSON.Deposit
            )
        }

        GlobalChartsController.Instance.chartWindowsLoadedFromProject = chartInstances

        this.SetLayoutSettingsFromJSON(chartInstances)

        this.TestingsSettings = this.getValidTestingSettings(projectJSON.TestingSettings)

        GlobalSymbolList.SymbolList.SeekToLastTickInProject()
    }

    private getValidTestingSettings(testingSettingsJSON: ProjectJSONTestingSettings): ILoadedTestingSettings {
        if (testingSettingsJSON && testingSettingsJSON.SliderPosition >= 1) {
            return {
                SliderPosition: testingSettingsJSON.SliderPosition,
                TestingAccuracyInMinutes: testingSettingsJSON.TestingAccuracyInMinutes
            }
        }

        return DEFAULT_TESTING_SETTINGS
    }

    private SetLayoutSettingsFromJSON(chartInstances: TChartWindow[]): void {
        const layoutSettings = this.GetLayoutSettingsFromJSON()

        if (layoutSettings) {
            this.setLayoutSettings(layoutSettings)
        }

        if (!this.isLayoutSettingsValid(chartInstances, layoutSettings)) {
            //if layout settings are not valid, let's set default layout settings according to the count of charts
            this.layoutSettings = this.getCorrespondingLayoutSettings(chartInstances.length) as ChartInfoType
        }
    }

    private isLayoutSettingsValid(chartInstances: TChartWindow[], layoutSettings: ChartInfoType | undefined): boolean {
        if (layoutSettings && layoutSettings.count !== chartInstances.length) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Count of charts in layout settings (${layoutSettings.count}) does not match count of charts in project (${chartInstances.length})`
            )
            return false
        }
        return true
    }

    private GetLayoutSettingsFromJSON(): ChartInfoType | undefined {
        if (this.downloadedProjectJSON && this.downloadedProjectJSON.Chart) {
            const chartAndLayoutSettings = JSON.parse(this.downloadedProjectJSON.Chart)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [layoutSettingsParsed, chartsParsed] = chartAndLayoutSettings as [ChartInfoType, ChartJSON[]]
            return layoutSettingsParsed
        }
        return undefined
    }

    private restoreChartsFromJSON(chartsJSON: ChartJSON[]): TChartWindow[] {
        const chartsInstances = []
        for (const chartJSON of chartsJSON) {
            const chartInstance = GlobalChartsController.Instance.createEmptyNewChart(chartJSON.symbolName)

            chartInstance.chartID = chartJSON.chartID
            chartInstance.isShown = chartJSON.isShown

            chartsInstances.push(chartInstance)
        }

        let priceAxisFontSize = 12
        let dateTimeScaleFontSize = 12

        if (chartsJSON.length > 0) {
            if (chartsJSON[0].PriceAxisFontSize && chartsJSON[0].PriceAxisFontSize > 0) {
                priceAxisFontSize = chartsJSON[0].PriceAxisFontSize
            }
            if (chartsJSON[0].DateTimeScaleFontSize && chartsJSON[0].DateTimeScaleFontSize > 0) {
                dateTimeScaleFontSize = chartsJSON[0].DateTimeScaleFontSize
            }
        }

        //TODO: get data from JSON for all charts

        GlobalOptions.Options.setPriceAxisFontSize(priceAxisFontSize)
        GlobalOptions.Options.setDateTimeScaleFontSize(dateTimeScaleFontSize)

        return chartsInstances
    }

    private InitDefaultCharts(symbols: string[]): TChartWindow[] {
        DebugUtils.logTopic(
            ELoggingTopics.lt_Loading,
            'restoreWholeProjectFromJSON - No charts found in JSON, probably new project'
        )

        const chartWindows = []

        for (const symbol of symbols) {
            const chartInstance = GlobalChartsController.Instance.createEmptyNewChart(symbol)
            chartInstance.isShown = true
            chartWindows.push(chartInstance)
        }

        this.layoutSettings = this.getCorrespondingLayoutSettings(chartWindows.length) as ChartInfoType
        TimeframeStore.setTimeframe(chartWindows[0].ChartOptions.Timeframe)

        const priceAxisFontSize = 12
        const dateTimeScaleFontSize = 12

        GlobalOptions.Options.setPriceAxisFontSize(priceAxisFontSize)
        GlobalOptions.Options.setDateTimeScaleFontSize(dateTimeScaleFontSize)

        return chartWindows
    }

    public setDataLoaded(): void {
        this.isProjectLoaded = true
    }

    public setSymbolName(chartWindow: TChartWindow): void {
        const chartJSON = this.GetJSONForChart(chartWindow.chartID)
        if (chartJSON) {
            chartWindow.SelectedSymbolName = chartJSON.symbolName
        }
    }

    private GetJSONForChart(chartID: number): ChartJSON | undefined {
        const chartsJSON = this.GetChartsJSON()
        if (!chartsJSON) {
            return undefined
        }

        for (const chartJSON of chartsJSON) {
            if (chartJSON.chartID === chartID) {
                return chartJSON
            }
        }
        return undefined
    }

    public loadChartWindowFromJSON(chartWindow: TChartWindow): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'loadChartWindowFromJSON')
        if (
            !this.downloadedProjectJSON ||
            this.isProjectLoaded ||
            this.countOfChartsLoaded >= this.countOfChartsToLoad
        ) {
            DebugUtils.logTopic(ELoggingTopics.lt_Loading, 'cannot load chart window from JSON yet')
            return
        }

        // Process each chart in charts; in this case, only for the given chartWindow
        const thisChartJSON = this.GetJSONForChart(chartWindow.chartID)

        if (thisChartJSON) {
            this.LoadChartWinAndObjectsFromJson(chartWindow, thisChartJSON)
        } else {
            //it seems that it is a new project so there are no saved charts yet
            DebugUtils.logTopic(
                ELoggingTopics.lt_Loading,
                'loadChartWindowFromJSON no charts found in JSON, initing with default options'
            )
            chartWindow.InitWithDefaultOptions()
        }

        this.countOfChartsLoaded++

        if (this.countOfChartsLoaded >= this.countOfChartsToLoad) {
            this.setDataLoaded()
        }

        GlobalChartsController.Instance.isForbiddenToChangeActiveChart = false
    }

    private LoadChartWinAndObjectsFromJson(chartWindow: TChartWindow, thisChartJSON: ChartJSON) {
        chartWindow.fromJSON(thisChartJSON)
        this.paintToolsFromJSON(thisChartJSON.paintTools, chartWindow)
        this.indicatorsFromJSON(thisChartJSON.indicators, chartWindow)
        this.oscWinAdd(thisChartJSON.oscWins, chartWindow)
    }

    private GetChartsJSON(): ChartJSON[] | undefined {
        let chartsJSON: ChartJSON[] | undefined = undefined

        if (this.downloadedProjectJSON && this.downloadedProjectJSON.Chart) {
            const chartAndLayoutSettings = JSON.parse(this.downloadedProjectJSON.Chart || '{}')
            const [layoutSettings, chartsParsed] = chartAndLayoutSettings as [ChartInfoType, ChartJSON[]]
            chartsJSON = chartsParsed
        }
        return chartsJSON
    }

    public processGlobalsLoadings(): void {
        if (!this.downloadedProjectJSON || this.isProjectLoaded) {
            return
        }
        GlobalTimezoneDSTController.Instance.updateDstFromServer(this.downloadedProjectJSON.DaylightSavingTime)
        GlobalTimezoneDSTController.Instance.updateTimezoneFromServer(this.downloadedProjectJSON.TimeZone)

        GlobalProjectInfo.ProjectInfo.deposit = this.downloadedProjectJSON.Deposit
        GlobalProjectInfo.ProjectInfo.StartDate = DateUtils.fromUnixTimeMilliseconds(
            this.downloadedProjectJSON.StartDate
        )
        GlobalProjectInfo.ProjectInfo.name = this.downloadedProjectJSON.ProjectName
        GlobalProjectInfo.ProjectInfo.FromDate = DateUtils.fromUnixTimeMilliseconds(
            this.downloadedProjectJSON.StartDate
        )

        GlobalProjectInfo.ProjectInfo.ForwardTestingOnly = this.downloadedProjectJSON.ForwardMode

        fetchAllTemplates()
            .then((templates) => {
                GlobalTemplatesManager.Instance.parseJSON(templates.data)
            })
            .catch((error) => {})

        this.CustomTimeframes = this.downloadedProjectJSON.CustomTimeframes

        TimeframeStore.updateCustomTimeFrames(this.downloadedProjectJSON.CustomTimeframes || [])
    }

    private restoreOrdersFromJSON(): void {
        if (!this.downloadedProjectJSON) {
            throw new StrangeError('Cannot restore orders from JSON, downloadedProjectJSON is not set')
        }

        const listWithOrders = new TOffsStringList()

        if (this.downloadedProjectJSON.CurrentOrders) {
            listWithOrders.LoadFromString(this.downloadedProjectJSON.CurrentOrders)
            GlobalProcessingCore.ProcessingCore.fromJSON(listWithOrders)
        }

        if (this.downloadedProjectJSON.OrdersHistory && this.downloadedProjectJSON.OrdersHistory.length > 0) {
            GlobalProcessingCore.ProcessingCore.loadHistoryFromJSON(this.downloadedProjectJSON.OrdersHistory)
        }

        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
        GlobalChartsController.Instance.RefreshCharts(true, true)
    }

    private getDataForHistory(): HistoryJSON[] {
        return GlobalProcessingCore.ProcessingCore.History.map((po) => {
            const order = po.tpos
            return {
                Ticket: order.ticket,
                Symbol: order.SymbolName,
                PosType: order.PosType,
                Lot: Number(this.doubleToWstringWithPrecision(order.lot, 2)),
                OpenTime: DateUtils.toUnixTimeMilliseconds(order.OpenTime),
                OpenPrice: Number(
                    this.doubleToWstringWithPrecision(order.OpenPrice, po.symbol?.symbolInfo.decimals || 5)
                ),
                Swap: order.swap,
                Commission: order.commission,
                ProfitPips: order.ProfitPips,
                Profit: Number(this.doubleToWstringWithPrecision(order.profit, po.symbol?.symbolInfo.decimals || 5)),
                StopLoss: order.StopLoss,
                TakeProfit: order.TakeProfit,
                Comments: order.Comments,
                ClosePrice: Number(
                    this.doubleToWstringWithPrecision(order.ClosePrice, po.symbol?.symbolInfo.decimals || 5)
                ),
                CloseTime: DateUtils.toUnixTimeMilliseconds(order.CloseTime),
                Margin: order.margin
            }
        })
    }

    private getOnlyNewHistory(serverJSON: ProjectJSONfromServer | null = null): HistoryJSON[] {
        if (!serverJSON) {
            return this.getDataForHistory()
        }

        const serverHistory = serverJSON.OrdersHistory

        if (!serverHistory) {
            return []
        }

        const localHistory = this.getDataForHistory()
        const newHistory = []
        for (const localOrder of localHistory) {
            let isFound = false
            for (const serverOrder of serverHistory) {
                if (serverOrder.Ticket === localOrder.Ticket) {
                    isFound = true
                    break
                }
            }
            if (!isFound) {
                newHistory.push(localOrder)
            }
        }
        return newHistory
    }

    private doubleToWstringWithPrecision(value: number, precision: number, trimTrailingNull = false): string {
        if (Number.isNaN(value)) {
            return 'NaN'
        }

        if (!Number.isFinite(value)) {
            return value < 0 ? '-inf' : 'inf'
        }

        let str: string = value.toFixed(precision)

        if (trimTrailingNull) {
            const dotPos: number = str.indexOf('.')
            if (dotPos !== -1) {
                str = str.replace(/0+$/, '')
                if (str.endsWith('.')) {
                    str = str.slice(0, -1)
                }
            }
        }

        return str
    }

    public saveProjectOnClose(): void {
        if (this.isPreset) {
            this.Reset()
        } else {
            SyncProjectManager.Instance.SaveProject().finally(() => {
                this.Reset()
            })
        }
    }

    public setHomeWorksToProject(homeWorks: string) {
        const projectJSON = this.getJSON()
        projectJSON.HomeWorks = homeWorks

        SyncProjectManager.Instance.forceProjectSave(projectJSON)
    }

    public getSymbols(): string[] {
        return this.symbols
    }

    public setSymbols(value: string[]) {
        this.symbols = value
    }

    public addCustomTimeFrame = (timeframeTemplate: CustomTimeFrame) => {
        this.CustomTimeframes = [...(this.CustomTimeframes ?? []), timeframeTemplate]
    }

    public removeCustomTimeFrame = (id: CustomTimeFrame['id']) => {
        this.CustomTimeframes = (this.CustomTimeframes ?? []).filter((tf) => tf.id !== id)
    }
}
